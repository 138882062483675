import { TokenService } from '@/utils/storage.service';
import ApiService from '@/utils/api.service';
import Auth from '@/api/Auth';
import Users from '@/api/Users';

const state = {
  errors: null,
  user: {},
  isAuthenticated: false,
  isMaster: false,
  message: null,
  route: null,
};

const getters = {
  user(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isMaster(state) {
    return state.isMaster;
  },
  message(state) {
    return state.message;
  },
  getRoute(state) {
    return state.route;
  },
};

const actions = {
  async login({ commit }, payload) {
    try {
      const data = await Auth.login(payload);
      const token = data?.data?.auth_token;
      const user = data?.data?.user?.data;

      await TokenService.saveToken(token);
      await TokenService.saveUser(user);
      await ApiService.setHeader();

      await commit('SET_AUTHENTICATED', true);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },

  async authInit({ commit }) {
    const isAuth = await TokenService.exists();
    let userData = await TokenService.getUser();
    const actualUserData = await Users.index({
      params: {
        elasticsearch: true,
        where: {
          id: userData.id,
        },
      },
    });

    if (JSON.stringify(userData.attributes) !== JSON.stringify(actualUserData.data.data[0].attributes)) {
      await TokenService.saveUser(actualUserData.data.data[0]);
      userData = await actualUserData.data.data[0];
    }

    const isMaster = userData.attributes.groups.data.find((el) => el.attributes.name === 'Master');

    commit('SET_USER', userData);
    commit('SET_AUTHENTICATED', isAuth);
    commit('SET_IS_MASTER', isMaster !== undefined);
  },

  logoutUser() {
    TokenService.removeAll();
  },
};

const mutations = {
  SET_AUTHENTICATED(state, payload) {
    state.isAuthenticated = payload;
  },
  SET_IS_MASTER(state, payload) {
    state.isMaster = payload;
  },
  SET_ERROR(state, error) {
    state.errors = error;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_USER(state, user) {
    state.user = user;
  },

  SET_ROUTE(state, route) {
    state.route = route;
  },

  LOG_OUT(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    TokenService.removeToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
