import ApiService from '@/utils/api.service';

const PricingSection = {
  index(data) {
    return ApiService.get(`pricing_sections`, data);
  },
  create(data) {
    return ApiService.post(`pricing_sections`, data);
  },
  show(id) {
    return ApiService.get(`pricing_sections/${id}`);
  },
  update(data) {
    return ApiService.put(`pricing_sections/${data.id}`, data.attributes);
  },
  delete(id) {
    return ApiService.delete(`pricing_sections/${id}`);
  },
};

export default PricingSection;
