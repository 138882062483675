import ApiService from '@/utils/api.service';

const Project = {
  index(data) {
    return ApiService.get(`subjects`, data);
  },
  single(id, data) {
    return ApiService.get(`subjects/${id}`, data);
  },
  names(data) {
    return ApiService.get(`subjects/names`, data);
  },
  mapList(data) {
    return ApiService.get(`subjects/map_list`, data);
  },
  statistics(data) {
    return ApiService.get(`subjects/statistics`, data);
  },
  create(data) {
    return ApiService.post(`subjects`, data);
  },
  checkCode(data) {
    return ApiService.post(`subjects/by_secret`, data);
  },
  bySecret(data) {
    return ApiService.post(`subjects/by_secret`, data);
  },
  bySecretSigned(data) {
    return ApiService.post(`subjects/by_secret_signed`, data);
  },
  makePdf(id) {
    return ApiService.post(`subjects/${id}/make_pdf`);
  },
  update(id, data) {
    return ApiService.put(`subjects/${id}`, data);
  },
  delete(id) {
    return ApiService.delete(`subjects/${id}`);
  },
};

export default Project;
