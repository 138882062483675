import Vue from 'vue';
import VueRouter from 'vue-router';

import { PublicProjectService } from '@/utils/storage.service';

import routes from '@/router/routes';
import middlewares from '@/router/middlewares';

// Store
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  base: '/',
  linkActiveClass: 'here',
  linkExactActiveClass: 'here',
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.matched.some((record) => record.meta?.middleware?.includes('authenticated')) ||
    to.matched.some((record) => record.meta?.middleware?.includes('unAuthenticated'))
  ) {
    await store.dispatch('auth/authInit').catch((er) => console.error('Error in getting user :>> ', er));
    await store.commit('auth/SET_ROUTE', to);
  }

  if (to.matched.some((record) => record.meta?.middleware?.includes('hasCode'))) {
    const secret = to.params?.id;
    const code = to.query?.code || PublicProjectService.getToken(secret);

    if (code) {
      await store.dispatch('projectPreview/initOverall', { secret, code, current: true, archive: false });
    }
  }

  // Check for route middlewares
  for (const r of to.matched) {
    if (r.meta && r.meta.middleware && Array.isArray(r.meta.middleware)) {
      for (let middleware of r.meta.middleware) {
        if (middlewares[middleware]) {
          const res = middlewares[middleware]();
          if (res.invalid) {
            return next(res.redirect || { name: 'login' });
          }
        }
      }
    }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  return next();
});

export default router;
