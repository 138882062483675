import axios from 'axios';
import { TokenService } from '@/utils/storage.service';

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },
  setHeader() {
    axios.defaults.headers.common['Authorization'] = TokenService.getToken() ? `Bearer ${TokenService.getToken()}` : '';
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource, config = {}) {
    return axios.get(resource, config);
  },

  post(resource, data, params) {
    return axios.post(resource, data, params);
  },
  put(resource, data) {
    return axios.put(resource, data);
  },
  delete(resource) {
    return axios.delete(resource);
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  },

  externalRequest(url, data) {
    let instance = axios.create();
    instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    instance.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
    instance.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
    instance.defaults.headers.common['Access-Control-Allow-Headers'] =
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers';
    delete instance.defaults.headers.common['Authorization'];
    delete instance.defaults.headers.common['X-CSRF-TOKEN'];
    const promise = instance.post(url, data);
    return promise
      .then(function (response) {
        return response;
      })
      .catch(() => {
        return false;
      });
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        let status = error.request.status;
        if (status === 401 || status === 419) {
          // TokenService.removeToken();
        }
        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },
};

export default ApiService;
