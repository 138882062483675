<template>
  <div v-bind="$attrs" class="w-100">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => KTApp.initSelect2());
  },
};
</script>
