import WeatherApiService from '@/utils/weather-api.service';

const Weather = {
  initIp() {
    return WeatherApiService.getIp();
  },
  initLocation(ip) {
    return WeatherApiService.getLocation(ip);
  },
  initWeather(location) {
    return WeatherApiService.get(location);
  },
};

export default Weather;
