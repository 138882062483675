import ApiService from '@/utils/api.service';

const MailTextTemplates = {
  index(data) {
    return ApiService.get(`mail_text_templates`, data);
  },
  single(id) {
    return ApiService.get(`mail_text_templates/${id}`);
  },
  create(data) {
    return ApiService.post(`mail_text_templates`, data);
  },
  update(data) {
    return ApiService.put(`mail_text_templates/${data.id}`, data);
  },
  delete(id) {
    return ApiService.delete(`mail_text_templates/${id}`);
  },
};

export default MailTextTemplates;
