<template>
  <div class="dataTables_wrapper">
    <div :class="[{ 'table-card': hasWrapper }]">
      <slot name="before_table" />
      <div v-if="items.length > 0" class="table-responsive">
        <table
          :class="['table table-row-bordered table-row-dashed align-middle gy-4', { 'table-hover': isHoverSupport }]"
        >
          <!--begin::Head-->
          <thead class="fw-bold text-uppercase fs-10 text-ls-sm">
            <tr>
              <th v-if="selectable">
                <label v-if="all" class="form-check form-check-sm form-check-custom form-check-solid">
                  <input
                    :value="all"
                    class="form-check-input"
                    type="checkbox"
                    @change="(el) => onAllRowSelected(el.target)"
                    :checked="isAllChecked"
                  />
                </label>
              </th>
              <th
                v-for="(head, index) in newHeader"
                :key="index"
                :class="[
                  'min-w-20px sorting',
                  head.class,
                  { sorting_asc: head.sortBy === 'asc' },
                  { sorting_desc: head.sortBy === 'desc' },
                ]"
                rowspan="1"
                colspan="1"
                v-html="head.title"
                @click="() => onSort(head)"
              />
            </tr>
          </thead>
          <!--end::Head-->
          <!--begin::Body-->
          <tbody>
            <template>
              <tr :class="index % 2 === 0 ? 'odd' : 'even'" v-for="(item, index) in items" :key="'tr' + index">
                <td v-if="selectable">
                  <label class="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      :value="item.id"
                      class="form-check-input"
                      type="checkbox"
                      @change="(el) => onRowToggle(el.target)"
                      :checked="checkedRows.includes(item.id)"
                    />
                  </label>
                </td>
                <td v-for="(head, key) in newHeader" :key="'key' + key" :class="head.class">
                  <slot :name="head.key" :item="item" />
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Body-->
        </table>
      </div>
      <px-placeholder v-else />
    </div>
    <div class="row align-items-center mt-8" v-if="paginate && pageCount > 0">
      <div v-if="totalCount > 10" class="col-md-auto">
        <px-select
          v-model="innerPer"
          classes="form-select w-75px"
          :items="perPageValues"
          track-by="value"
          text-by="value"
          :select2-id="`select2_per_page_${tableId}`"
          :minimum-results-for-search="Infinity"
          @input="perPageChange"
        />
      </div>
      <div class="col-md-auto">
        <div class="dataTables_info py-0">Showing {{ fromPage }} to {{ toPage }} of {{ totalCount }} records</div>
      </div>
      <div v-if="pageInfo.total_pages > 1" class="col-md-auto ms-auto">
        <px-pagination
          ref="pagination"
          :pageCount="pageCount"
          :pageRange="+innerPer"
          :marginPages="2"
          :clickHandler="pageChange"
          :currentPage="currentPage"
          prevText="<i class='previous' />"
          prevLinkClass="page-link"
          prevClass="paginate_button page-item previous"
          nextText="<i class='next' />"
          nextLinkClass="page-link"
          nextClass="paginate_button page-item next"
          containerClass="pagination"
          pageClass="paginate_button page-item"
          pageLinkClass="page-link"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableId: {
      type: String,
      default: 'table',
    },
    isHoverSupport: {
      type: Boolean,
      default: true,
    },
    per: {
      type: Number,
      default: 10,
    },
    pageInfo: {
      type: Object,
      default: () => ({
        total_pages: 1,
        all_count: 1,
        count: 1,
      }),
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    hasWrapper: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
    },
    header: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      innerPer: 0,
      newHeader: [],
      perPageValues: [
        {
          id: '1001',
          value: 10,
        },
        {
          id: '1002',
          value: 20,
        },
        {
          id: '1003',
          value: 30,
        },
        {
          id: '1004',
          value: 50,
        },
      ],

      isAllChecked: false,
      checkedRows: [],
      all: null,
    };
  },
  watch: {
    items(newVal) {
      if (newVal.length) {
        const all = [];
        this.items.forEach((el) => {
          all.push(el.id);
        });

        const bools = [];
        all.forEach((el) => {
          bools.push(this.checkedRows.includes(el));
        });

        this.all = all;
        this.isAllChecked = !bools.includes(false);
      }
    },
  },
  computed: {
    pageCount() {
      return this.pageInfo.total_pages ?? 1;
    },
    toPage() {
      return this.page !== this.pageCount ? this.page * this.innerPer : this.totalCount;
    },
    totalCount() {
      return this.pageInfo.all_count ?? 1;
    },
    fromPage() {
      return this.page !== 1 ? (this.page - 1) * this.innerPer + 1 : 1;
    },
    currentPage() {
      return this.$route.query.page ? +this.$route.query.page : 1;
    },
  },
  mounted() {
    this.newHeader = this.header;
    this.innerPer = this.per;
  },
  methods: {
    onSort(head) {
      if (!head.sort) {
        return false;
      }

      this.newHeader = this.newHeader.map((el) => {
        if (el.key === head.key) {
          el.sortBy = el.sortBy === 'desc' ? 'asc' : 'desc';
        }

        return el;
      });

      this.$emit('on-sort', head);
    },
    pageChange(page) {
      this.page = page;
      this.isAllChecked = false;
      this.$emit('on-page-change', this.page);
    },
    perPageChange() {
      this.$emit('on-per-page-change', this.innerPer);
    },

    onAllRowSelected(evt) {
      const valAsArray = evt.value.split(',');

      this.isAllChecked = evt.checked;

      if (evt.checked) {
        this.checkedRows = this.checkedRows.concat(valAsArray);
      } else {
        const newArr = [];

        this.checkedRows.forEach((el) => {
          if (!valAsArray.includes(el)) newArr.push(el);
        });

        this.checkedRows = newArr;
      }

      this.$emit('on-row-check', this.checkedRows);
    },
    onRowToggle(evt) {
      if (evt.checked) {
        this.checkedRows = [...this.checkedRows, evt.value];
      } else {
        this.checkedRows = this.checkedRows.filter((el) => el !== evt.value);
      }

      const bools = [];
      this.all.forEach((el) => {
        bools.push(this.checkedRows.includes(el));
      });

      this.isAllChecked = !bools.includes(false);

      this.$emit('on-row-check', this.checkedRows);
    },

    clearAllChecked() {
      this.isAllChecked = false;
      this.checkedRows = [];
      this.all = null;
    },
  },
};
</script>
