<template>
  <div v-if="isVisible">
    <div
      :class="['modal fade', { show: isVisible }]"
      tabindex="-1"
      role="dialog"
      :style="{
        display: 'block',
      }"
    >
      <!--begin::Modal dialog-->
      <div :class="['modal-dialog modal-dialog-centered position-relative', modalSize]" style="z-index: 1055">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header px-lg-10">
            <!--begin::Modal title-->
            <slot name="title" />
            <!--end::Modal title-->
            <!--begin::Close-->
            <div id="hide-modal" class="btn btn-xs btn-light btn-icon btn-active-color-primary" @click="innerClose">
              <i class="bi bi-x text-muted" />
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body py-lg-10 px-lg-10">
            <slot name="content" />
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <div :class="['modal-backdrop fade', { show: isVisible }]" />
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    modalSize: {
      type: String,
      default: 'modal-lg',
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  updated() {
    if (this.isVisible) {
      document.body.classList.add('modal-open');
      setTimeout(() => {
        document.body.addEventListener('click', this.onBodyClick);
      });
    } else {
      if (document.querySelectorAll('.modal.show').length < 1) {
        document.body.classList.remove('modal-open');
        document.body.removeEventListener('click', this.onBodyClick);
      }
    }
  },
  methods: {
    innerClose() {
      this.closeModal();
    },
    onBodyClick(evt) {
      if (evt.target.closest('.modal') && evt.target.classList.contains('modal')) {
        this.innerClose();
      }
    },
  },
};
</script>
