// Old
// export const GAUTH_KEY = '4397251741-qj3k980da8pfbhvkgjv800059tgooe8b.apps.googleusercontent.com';

// New
// export const GAUTH_KEY = '760696905696-oc7p76pacoro113t1ef9ct27erp2rnv5.apps.googleusercontent.com';
export const GAUTH_KEY = '463059530042-oi35eg417odln5vj5mnve16af7i4eokd.apps.googleusercontent.com';

export const X_RAPIDAPI_KEY = '7af9c91f78mshcce32d555af279bp171c15jsn3d9a5197bb9e';
export const X_RAPIDAPI_HOST = 'visual-crossing-weather.p.rapidapi.com';

export const OPEN_WEATHER_MAP_KEY = '78b11b4914a1df84b1d842cd136ae125';
