import { containsNumbers } from '@/shared/helpers';

import Weather from '@/api/Weather';

const state = {
  city: null,
  region: null,
  country: null,
  forecast: null,
  currentDayForecast: null,
  fiveDaysForecast: [],
  message: null,
};

const getters = {
  city(state) {
    return state.city;
  },
  region(state) {
    return state.region;
  },
  country(state) {
    return state.country;
  },
  forecast(state) {
    return state.forecast;
  },
  currentDayForecast(state) {
    return state.currentDayForecast;
  },
  fiveDaysForecast(state) {
    return state.fiveDaysForecast;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async initOverall({ commit, getters }) {
    try {
      const newIp = await Weather.initIp();
      const { city, region, country_name } = await Weather.initLocation(newIp);

      await commit('SET_CITY', city);
      await commit('SET_REGION', containsNumbers(region) ? null : region);
      await commit('SET_COUNTRY', country_name);

      const location = getters.region
        ? `${getters.city},${getters.region},${getters.country}`
        : `${getters.city},${getters.country}`;
      const newForecast = await Weather.initWeather(location);

      await commit('SET_FORECAST', newForecast.data.locations[location]);

      return true;
    } catch (e) {
      await commit('SET_FORECAST', null);
    }
  },
  async updateOverall({ commit, getters }) {
    const location = getters.region
      ? `${getters.city},${getters.region},${getters.country}`
      : `${getters.city},${getters.country}`;
    const newForecast = await Weather.initWeather(location);

    await commit('SET_FORECAST', newForecast.data.locations[location]);

    return true;
  },
  currentDayForecast({ commit, getters }) {
    commit('SET_CURRENT_DAY_FORECAST', getters.forecast.values[0]);
  },
  fiveDaysForecast({ commit, getters }) {
    commit('SET_FIVE_DAYS_FORECAST', [...getters.forecast.values.slice(1, 6)]);
  },
};

const mutations = {
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_REGION(state, payload) {
    state.region = payload;
  },
  SET_COUNTRY(state, payload) {
    state.country = payload;
  },
  SET_FORECAST(state, payload) {
    state.forecast = payload;
  },
  SET_CURRENT_DAY_FORECAST(state, payload) {
    state.currentDayForecast = payload;
  },
  SET_FIVE_DAYS_FORECAST(state, payload) {
    state.fiveDaysForecast = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
