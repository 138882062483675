import ApiService from '@/utils/api.service';

const LeadTasks = {
  initOverall(params) {
    return ApiService.get(`lead_tasks`, params);
  },
  single(id) {
    return ApiService.get(`lead_tasks/${id}`);
  },
  create(data) {
    return ApiService.post(`lead_tasks`, data);
  },
  update({ id, attributes }) {
    return ApiService.put(`lead_tasks/${id}`, attributes);
  },
  delete(id) {
    return ApiService.delete(`lead_tasks/${id}`);
  },
};

export default LeadTasks;
