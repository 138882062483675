<template>
  <v-popover
    v-bind="$attrs"
    :popoverClass="`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary d-block py-3 ${contentClasses}`"
    openClass="show"
    v-on="$listeners"
  >
    <slot v-if="isDefaultBtnHidden" name="btn" role="button" />
    <span v-else :class="['tooltip-target', btnClasses]" role="button">
      <i :class="[iconClasses, 'pe-0']" />
    </span>

    <template slot="popover">
      <slot name="content" />
    </template>
  </v-popover>
</template>

<script>
export default {
  props: {
    isDefaultBtnHidden: {
      type: Boolean,
      default: false,
    },
    btnClasses: {
      type: String,
      default: 'btn btn-flex btn-link py-0',
    },
    iconClasses: {
      type: String,
      default: 'fa-solid fa-ellipsis-vertical text-gray-400',
    },
    contentClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
<style lang="scss">
.tooltip {
  .tooltip-inner {
    padding: 0;
    box-shadow: none !important;
  }
}

.popover {
  max-width: 200px !important;
}

.tooltip-inner {
  max-width: none !important;
}
</style>
