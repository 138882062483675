import FiberglassPoolTemplate from '@/api/FiberglassPoolTemplate';

const state = {
  items: [],
  message: null,
};

const getters = {
  items(state) {
    return state.items;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.order = params.order || 'asc';
      params.order_by = params.order_by || 'sid';

      const data = await FiberglassPoolTemplate.index({ params });
      await commit('SET_ITEMS', data.data);

      return true;
    } catch (e) {
      await commit('SET_ITEMS', []);
    }
  },
  async create({ commit }, payload) {
    try {
      const data = await FiberglassPoolTemplate.create(payload);
      await commit('SET_MESSAGE', data);
      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async single(_, id) {
    try {
      return await FiberglassPoolTemplate.single(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const data = await FiberglassPoolTemplate.update(payload);
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async delete({ commit }, id) {
    try {
      const data = await FiberglassPoolTemplate.delete(id);
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
