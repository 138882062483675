import ApiService from '@/utils/api.service';

const Proposal = {
  index(data) {
    return ApiService.get(`proposal_scopes`, data);
  },
  single(id, data) {
    return ApiService.get(`proposal_scopes/${id}`, data);
  },
  names(data) {
    return ApiService.get(`proposal_scopes/names`, data);
  },
  statements(id) {
    return ApiService.get(`proposal_scopes/${id}/statements`);
  },
  create(data) {
    return ApiService.post(`proposal_scopes`, data);
  },
  createNewVersion(id) {
    return ApiService.post(`proposal_scopes/${id}/new_version`);
  },
  duplicate(data) {
    return ApiService.post(`proposal_scopes/${data.id}/duplicate`, data);
  },
  saveAsTemplate(data) {
    return ApiService.post(`proposal_scopes/${data.id}/save_as_template`, data);
  },
  megaUpdate(data) {
    return ApiService.post(`proposal_scopes/${data.proposal_scope_id}/mega_update`, data);
  },
  // share(data) {
  //   const targetProposalId = data.target_proposal_id;
  //   const newData = data;
  //   delete newData.target_proposal_id;
  //
  //   return ApiService.post(`proposal_scopes/${targetProposalId}/share`, newData);
  // },
  share(data) {
    return ApiService.post(`proposal_scopes/share`, data);
  },
  makePdf(id) {
    return ApiService.post(`proposal_scopes/${id}/make_pdf`);
  },
  sendForSign(id) {
    return ApiService.post(`proposal_scopes/${id}/send_to_pandadoc`);
  },
  uploadSignedPdfFile(data) {
    return ApiService.post(`proposal_scopes/${data.id}/upload_signed_pdf_file`, data.signed_pdf_file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  removeSignedPdfFile(id) {
    return ApiService.post(`proposal_scopes/${id}/remove_signed_pdf_file`);
  },
  delete(id) {
    return ApiService.delete(`proposal_scopes/${id}`);
  },
  update(data) {
    return ApiService.put(`proposal_scopes/${data.id}`, data);
  },
  bulkUpdate(data) {
    return ApiService.post(`proposal_scopes/bulk_update`, data);
  },
};

export default Proposal;
