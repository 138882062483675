<template>
  <transition name="fade">
    <div :class="['box', 'l-bg', classes, { 'full-window': isFullWindow }]">
      <div class="loader-16" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PxLoader',
  props: {
    isFullWindow: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: 'blue-light', // blue-light | blue | white | dark
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  overflow: hidden !important;
}
.l-bg {
  background-color: transparent !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.box.white,
.box.dark,
.box.blue {
  transition: 0.3s color, 0.3s border, 0.3s transform;
}

.box.white {
  background: #fff;
}

.box.dark {
  background: #000;
}

.box.blue {
  background: #1738a2e8;
}

.box.blue-light {
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  background: #1738a2e8;
}

.box.full-window {
  position: fixed;
  z-index: 999999999;
}

.box:not(.full-window) {
  position: absolute;
  z-index: 10;
  border-radius: 0.475rem;
}

[class*='loader-'] {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: #1738a2e8;
  vertical-align: middle;
  pointer-events: none;
}

////////////////////////////////////////////
.loader-16 {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    animation: 1s spin linear infinite;
  }

  &:before {
    transform: rotateX(70deg);
  }

  &:after {
    transform: rotateY(70deg);
    animation-delay: 0.4s;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0 0 0 currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0 currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
</style>
