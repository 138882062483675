<template>
  <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
    <span class="svg-icon">
      <inline-svg src="/assets/media/icons/duotune/arrows/arr066.svg" />
    </span>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => KTScrolltop.createInstances());
  },
};
</script>
