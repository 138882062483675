import PricingSection from '@/api/PricingSection';

const state = {
  items: [],
  message: null,
};

const getters = {
  items(state) {
    return state.items;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.proposalScopeId) params.where = { proposal_scope_id: params.proposalScopeId };
      delete params.proposalScopeId;

      const data = await PricingSection.index({ params });

      await commit('SET_ITEMS', data.data);

      return true;
    } catch (e) {
      await commit('SET_ITEMS', []);
    }
  },
  async create({ commit }, payload) {
    try {
      const data = await PricingSection.create(payload);
      await commit('SET_MESSAGE', data);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async show(_, id) {
    try {
      return await PricingSection.show(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const data = await PricingSection.update(payload);
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async delete({ commit }, id) {
    try {
      const data = await PricingSection.delete(id);
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
