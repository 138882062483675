/* eslint-disable no-undef */
import { TokenService } from '@/utils/storage.service';
import ApiService from '@/utils/api.service';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoading: true,
      numberFieldMask: {
        decimal: '.',
        thousands: ',',
        precision: 1,
        masked: false,
      },
      quantity: {
        decimal: '.',
        thousands: ',',
        precision: 0,
        masked: false,
      },
      money: {
        decimal: '.',
        thousands: ',',
        precision: 0,
        masked: false,
      },
      moneyWithPrecision: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/user',
    }),
    /* Global computed properties */
    user() {
      return TokenService.getUser();
    },
    currentUser() {
      return this.authUser;
    },
    hasExtendedRoles() {
      const groups = this.currentUser.attributes.groups.data;

      return (
        !!groups.find((el) => el.attributes.name === 'Admin') ||
        !!groups.find((el) => el.attributes.name === 'Master') ||
        !!groups.find((el) => el.attributes.name === 'Manager')
      );
    },
  },
  methods: {
    async downloadPdf(fileURL, fileName) {
      const response = await ApiService.get(fileURL, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      link.click();
      URL.revokeObjectURL(link.href);
    },
    async loading(func) {
      await func();

      this.isLoading = false;
    },
    downloadPdfFromTab(fileURL, fileName, params = {}) {
      const link = document.createElement('a');
      link.href = fileURL;
      if (params && params.secret) link.searchParams = params.secret;
      if (params && params.code) link.searchParams = params.code;
      link.download = fileName;

      const redirectWindow = window.open(link.href, '_blank');

      document.body.appendChild(link);
      redirectWindow.location;
      document.body.removeChild(link);
    },
    hasQueryParams(route) {
      return !!Object.keys(route.query).length;
    },
    select2Default(id, val) {
      $(id).val(val).trigger('change');
    },
    // @param: String
    firstLettersOfEachWord(str) {
      const strToArr = str.split(' ');
      const firstLetters = strToArr.map((el) => el.charAt(0));

      return firstLetters.join('');
    },
    // @param: Array
    separateStringWithComma(arr) {
      const filteredArr = arr.filter((el) => el);

      return filteredArr.join(', ');
    },
    // @param: String
    replaceBreakWithBrTag(str) {
      return str ? str.replace(/\n/g, '<br />') : '';
    },
    /* Global methods  */
    v_toast(code, message = '', cb = () => {}, params = {}) {
      let type = code.toString().startsWith('20') ? 'success' : 'error';

      toast
        .fire({
          type: type,
          icon: type,
          title: message,
          ...params,
        })
        .then(() => {
          cb();
        });
    },
    hideModal(id = '#hide-modal') {
      const modalId = document.body.querySelector(id);

      document.body.classList.remove('modal-open');
      modalId.click();
    },
    validateModel(model) {
      return model ? 'border-danger' : '';
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
      return formData;
    },
    jsonToFormData(data) {
      return this.buildFormData(new FormData(), data);
    },
    splitString(data, separator = '.', def = 0) {
      if (data.toString().includes(separator)) {
        return data.toString().split(separator);
      }

      return [data, def];
    },
    normalizeString(str) {
      if (str) {
        return str.startsWith('"') ? JSON.parse(str) : str;
      }

      if (str === null) {
        return '';
      }
    },
    scopeNameByParam(param) {
      switch (param) {
        case 'Swimming Pool':
          return 'Watershape';
        case 'Landscaping':
          return 'Landscaping';
        case 'Woodworking':
          return 'Woodworking';
        default:
          return null;
      }
    },
    countTrueQty(arr, init = 0) {
      const filteredArr = arr.filter((el) => el === true);

      return init + filteredArr.length;
    },
    activeRadioBtn(type, model, active = 'active') {
      return model == type ? active : '';
    },
    compareYears(a, b) {
      const aDate = new Date(a);
      const bDate = new Date(b);

      return aDate.getYear() === bDate.getYear();
    },
    // @param: String[]
    arrayToString(arr) {
      return arr.join(',');
    },
    compareDates(a, b) {
      const aDate = new Date(a);
      const bDate = new Date(b);

      return (
        aDate.getYear() === bDate.getYear() &&
        aDate.getMonth() === bDate.getMonth() &&
        aDate.getDay() === bDate.getDay()
      );
    },
    isCurrentYear(a) {
      const targetDate = new Date(a);
      const currentDate = new Date(Date.now());

      return currentDate.getFullYear() === targetDate.getFullYear();
    },
    scopeTypeFirstLetter(type) {
      switch (type) {
        case 'Swimming Pool':
          return 'P';
        case 'Landscaping':
          return 'L';
        case 'Woodworking':
          return 'W';
        default:
          return 'N';
      }
    },
    scopeTypeBg(type) {
      switch (type) {
        case 'Swimming Pool':
          return 'bg-primary';
        case 'Landscaping':
          return 'bg-success';
        case 'Woodworking':
          return 'bg-warning';
        default:
          return 'bg-dark';
      }
    },
    transformToFtAndIn(val) {
      const newVal = val ? `${val}`.split('.') : ['0', '0'];

      return {
        ft: newVal[0] || '0',
        in: newVal[1] || '0',
      };
    },
    degreeToDirection(val) {
      if (val > 11.25 && val < 33.75) {
        return 'North/North-East';
      } else if (val > 33.75 && val < 56.25) {
        return 'North-East';
      } else if (val > 56.25 && val < 78.75) {
        return 'East/North-East';
      } else if (val > 78.75 && val < 101.25) {
        return 'East';
      } else if (val > 101.25 && val < 123.75) {
        return 'East/South-East';
      } else if (val > 123.75 && val < 146.25) {
        return 'South-East';
      } else if (val > 146.25 && val < 168.75) {
        return 'South/South-East';
      } else if (val > 168.75 && val < 191.25) {
        return 'South';
      } else if (val > 191.25 && val < 213.75) {
        return 'South/South-West';
      } else if (val > 213.75 && val < 236.25) {
        return 'South-West';
      } else if (val > 236.25 && val < 258.75) {
        return 'West/South-West';
      } else if (val > 258.75 && val < 281.25) {
        return 'West';
      } else if (val > 281.25 && val < 303.75) {
        return 'West/North-West';
      } else if (val > 303.75 && val < 326.25) {
        return 'North-West';
      } else if (val > 326.25 && val < 348.75) {
        return 'North/North-West';
      }

      return 'North';
    },
    // Temp
    logV(info) {
      console.log(info);
    },
  },
};
