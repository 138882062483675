import ApiService from '@/utils/api.service';

const PricingItemTemplate = {
  index(data) {
    return ApiService.get(`pricing_item_templates`, data);
  },
  create(data) {
    return ApiService.post(`pricing_item_templates`, data);
  },
  show(id) {
    return ApiService.get(`pricing_item_templates/${id}`);
  },
  single(id) {
    return ApiService.get(`pricing_item_templates/${id}`);
  },
  update(data) {
    return ApiService.put(`pricing_item_templates/${data.id}`, { pricing_item_template: data.attributes });
  },
  delete(id) {
    return ApiService.delete(`pricing_item_templates/${id}`);
  },
};

export default PricingItemTemplate;
