import ApiService from '@/utils/api.service';

const Units = {
  index(data) {
    return ApiService.get(`units`, data);
  },
  single(id) {
    return ApiService.get(`units/${id}`);
  },
  create(data) {
    return ApiService.post(`units`, data);
  },
  update(data) {
    return ApiService.put(`units/${data.id}`, data);
  },
  delete(id) {
    return ApiService.delete(`units/${id}`);
  },
};

export default Units;
