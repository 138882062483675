import Proposal from '@/api/Proposal';
import Adjustments from '@/api/Adjustments';
import Payments from '@/api/Payments';
import Project from '@/api/Project';

const state = {
  project: null,
  statements: [],
  statements_contract: [],
  statements_change_orders: [],
  statements_adjustments: [],
  statements_payments: [],
  statements_contracts: [],
  internal_notes: '',
  message: null,
  pdf_file: null,
  pdf_ready: false,
  sharing: false,
};

const getters = {
  project(state) {
    return state.project;
  },
  statements(state) {
    return state.statements;
  },
  statementsContract(state) {
    return state.statements_contract;
  },
  statementsChangeOrders(state) {
    return state.statements_change_orders;
  },
  statementsAdjustments(state) {
    return state.statements_adjustments;
  },
  statementsPayments(state) {
    return state.statements_payments;
  },
  statementsContracts(state) {
    return state.statements_contracts;
  },
  internalNotes(state) {
    return state.internal_notes;
  },
  message(state) {
    return state.message;
  },
  pdfFile(state) {
    return state.pdf_file;
  },
  pdfReady(state) {
    return state.pdf_ready;
  },
  sharing(state) {
    return state.sharing;
  },
};

const actions = {
  async initProject({ commit }, { id, params = {} }) {
    try {
      if (params.fields) params.fields = params.fields || '';
      const project = await Project.single(id, { params });

      await commit('SET_PROJECT', project?.data?.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async updateProject({ commit, getters }, { id, attributes }) {
    try {
      const updatedProject = await Project.update({
        id,
        attributes,
      });

      await commit('SET_PROJECT', {
        ...getters.project,
        attributes: {
          ...getters.project.attributes,
          ...attributes,
        },
      });
      await commit('SET_MESSAGE', updatedProject);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },

  async initStatementContract({ commit, getters }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {
        current: true,
        archive: false,
        status: 'Signed',
      };
      if (typeof params.subject_id !== 'undefined') params.where.subject_id = params.subject_id;
      delete params.subject_id;
      if (typeof params.scope_type !== 'undefined') params.where.scope_type = params.scope_type;
      const scopeType = params.scope_type;
      delete params.scope_type;

      const newContract = await Proposal.index({ params });

      await commit('SET_STATEMENTS_CONTRACT', [
        ...getters.statementsContract,
        {
          type: scopeType,
          data: newContract.data.data[0],
        },
      ]);

      return true;
    } catch (e) {
      await commit('SET_STATEMENTS_CONTRACT', [...getters.statementsContract]);
    }
  },
  updateStatementContract({ commit, getters }, { scopeType, attributes }) {
    const updatedItem = getters.statementsContract.map((el) => {
      if (el.type === scopeType) {
        el.data = {
          ...el.data,
          attributes: {
            ...el.data.attributes,
            ...attributes,
          },
        };
      }

      return el;
    });

    commit('SET_STATEMENTS_CONTRACT', updatedItem);
  },

  async initStatements({ commit, getters }, params = {}) {
    try {
      const id = params.id;
      delete params.id;
      const scopeType = params.scope_type;
      delete params.scope_type;

      const newStatements = await Proposal.statements(id);

      await commit('SET_STATEMENTS', [...getters.statements, { type: scopeType, data: newStatements.data }]);

      return true;
    } catch (e) {
      await commit('SET_STATEMENTS', [...getters.statements]);
    }
  },
  async updateStatements({ commit, getters }, { id, scopeType }) {
    try {
      const newStatements = await Proposal.statements(id);
      const newItems = getters.statements.map((el) => {
        if (el.type === scopeType) {
          el.data = newStatements.data;
        }

        return el;
      });

      await commit('SET_STATEMENTS', newItems);
    } catch (e) {
      await commit('SET_STATEMENTS', [...getters.statements]);
    }
  },
  async initStatementChangeOrders({ commit, getters }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {
        archive: false,
        state: 'Change Order',
      };
      if (typeof params.subject_id !== 'undefined') params.where.subject_id = params.subject_id;
      delete params.subject_id;
      if (typeof params.parent_id !== 'undefined') params.where.parent_id = params.parent_id;
      delete params.parent_id;
      if (typeof params.scope_type !== 'undefined') params.where.scope_type = params.scope_type;
      const scopeType = params.scope_type;
      delete params.scope_type;

      const newChangeOrders = await Proposal.index({ params });

      await commit('SET_STATEMENTS_CHANGE_ORDERS', [
        ...getters.statementsChangeOrders,
        { type: scopeType, data: newChangeOrders.data.data },
      ]);

      return true;
    } catch (e) {
      await commit('SET_STATEMENTS_CHANGE_ORDERS', [...getters.statementsChangeOrders]);
    }
  },

  async initStatementAdjustments({ commit, getters }, params = {}) {
    try {
      params.elasticsearch = true;
      if (typeof params.proposal_scope_id !== 'undefined') params.where = {};
      if (typeof params.proposal_scope_id !== 'undefined') params.where.proposal_scope_id = params.proposal_scope_id;
      delete params.proposal_scope_id;
      const scopeType = params.scope_type;
      delete params.scope_type;

      const newAdjustments = await Adjustments.initOverall({ params });

      await commit('SET_STATEMENTS_ADJUSTMENTS', [
        ...getters.statementsAdjustments,
        { type: scopeType, data: newAdjustments.data.data },
      ]);

      return true;
    } catch (e) {
      await commit('SET_STATEMENTS_ADJUSTMENTS', [...getters.statementsAdjustments]);
    }
  },
  addStatementAdjustments({ commit, getters }, newItem) {
    const scopeType = newItem.data.data.attributes.proposal_scope.data.attributes.scope_type;
    const newItems = getters.statementsAdjustments.map((el) => {
      if (el.type === scopeType) {
        el.data = [...el.data, newItem.data.data];
      }

      return el;
    });

    commit('SET_STATEMENTS_ADJUSTMENTS', newItems);
  },
  duplicateStatementAdjustment({ commit, getters }, { item, scopeType }) {
    const newItems = getters.statementsAdjustments.map((el) => {
      if (el.type === scopeType) {
        el.data = [...el.data, item];
      }

      return el;
    });

    commit('SET_STATEMENTS_ADJUSTMENTS', newItems);
  },
  updateStatementAdjustment({ commit, getters }, { id, scopeType, attributes }) {
    const newItems = getters.statementsAdjustments.map((el) => {
      if (el.type === scopeType) {
        el.data.map((elI) => {
          if (elI.id === id) {
            elI.attributes = {
              ...elI.attributes,
              ...attributes,
            };
          }

          return elI;
        });
      }

      return el;
    });

    commit('SET_STATEMENTS_ADJUSTMENTS', newItems);
  },
  deleteStatementAdjustment({ commit, getters }, { id, scopeType }) {
    const newItems = getters.statementsAdjustments.map((el) => {
      if (el.type === scopeType) {
        el.data = el.data.filter((elI) => elI.id !== id);
      }

      return el;
    });

    commit('SET_STATEMENTS_ADJUSTMENTS', newItems);
  },

  async initStatementPayments({ commit, getters }, params = {}) {
    try {
      params.elasticsearch = true;
      if (typeof params.proposal_scope_id !== 'undefined') params.where = {};
      if (typeof params.proposal_scope_id !== 'undefined') params.where.proposal_scope_id = params.proposal_scope_id;
      delete params.proposal_scope_id;
      const scopeType = params.scope_type;
      delete params.scope_type;

      const newPayments = await Payments.initOverall({ params });

      await commit('SET_STATEMENTS_PAYMENTS', [
        ...getters.statementsPayments,
        { type: scopeType, data: newPayments.data.data },
      ]);

      return true;
    } catch (e) {
      await commit('SET_STATEMENTS_PAYMENTS', [...getters.statementsPayments]);
    }
  },
  addStatementPayments({ commit, getters }, newItem) {
    const scopeType = newItem.data.data.attributes.proposal_scope.data.attributes.scope_type;
    const newItems = getters.statementsPayments.map((el) => {
      if (el.type === scopeType) {
        el.data = [...el.data, newItem.data.data];
      }

      return el;
    });

    commit('SET_STATEMENTS_PAYMENTS', newItems);
  },
  duplicateStatementPayment({ commit, getters }, { item, scopeType }) {
    const newItems = getters.statementsPayments.map((el) => {
      if (el.type === scopeType) {
        el.data = [...el.data, item];
      }

      return el;
    });

    commit('SET_STATEMENTS_PAYMENTS', newItems);
  },
  updateStatementPayment({ commit, getters }, { id, scopeType, attributes }) {
    const newItems = getters.statementsPayments.map((el) => {
      if (el.type === scopeType) {
        el.data.map((elI) => {
          if (elI.id === id) {
            elI.attributes = {
              ...elI.attributes,
              ...attributes,
            };
          }

          return elI;
        });
      }

      return el;
    });

    commit('SET_STATEMENTS_PAYMENTS', newItems);
  },
  deleteStatementPayment({ commit, getters }, { id, scopeType }) {
    const newItems = getters.statementsPayments.map((el) => {
      if (el.type === scopeType) {
        el.data = el.data.filter((elI) => elI.id !== id);
      }

      return el;
    });

    commit('SET_STATEMENTS_PAYMENTS', newItems);
  },

  async initStatementContracts({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {
        current: true,
        archive: false,
        status: 'Signed',
      };
      if (typeof params.subject_id !== 'undefined') params.where.subject_id = params.subject_id;
      delete params.subject_id;

      const data = await Proposal.index({ params });

      await commit('SET_STATEMENTS_CONTRACTS', data.data.data);

      return true;
    } catch (e) {
      await commit('SET_STATEMENTS_CONTRACTS', null);
    }
  },

  async makePdf({ commit }, id) {
    try {
      const res = await Project.makePdf(id);

      await commit('SET_MESSAGE', res);
      await commit('SET_PDF_FILE', res.data.pdf_file);
      await commit('SET_PDF_READY', true);

      return res;
    } catch (e) {
      throw new Error(e);
    }
  },

  changeBalanceOutstanding({ commit, getters }, params) {
    let newOutstanding = +getters.project.attributes.balance.outstanding;

    switch (params.operation) {
      case 'decrease':
        newOutstanding = +getters.project.attributes.balance.outstanding - params.amount;
        break;
      case 'increase':
        newOutstanding = +getters.project.attributes.balance.outstanding + params.amount;
        break;
      default:
        break;
    }

    const updatedProject = {
      ...getters.project,
      attributes: {
        ...getters.project.attributes,
        balance: {
          ...getters.project.attributes.balance,
          outstanding: newOutstanding,
        },
      },
    };

    commit('SET_PROJECT', updatedProject);
  },
};

const mutations = {
  SET_PROJECT(state, payload) {
    state.project = payload;
  },
  SET_STATEMENTS_CONTRACT(state, payload) {
    state.statements_contract = payload;
  },
  SET_STATEMENTS(state, payload) {
    state.statements = payload;
  },
  SET_STATEMENTS_CHANGE_ORDERS(state, payload) {
    state.statements_change_orders = payload;
  },
  SET_STATEMENTS_ADJUSTMENTS(state, payload) {
    state.statements_adjustments = payload;
  },
  SET_STATEMENTS_PAYMENTS(state, payload) {
    state.statements_payments = payload;
  },
  SET_STATEMENTS_CONTRACTS(state, payload) {
    state.statements_contracts = payload;
  },
  SET_INTERNAL_NOTES(state, payload) {
    state.internal_notes = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_PDF_FILE(state, payload) {
    state.pdf_file = payload;
  },
  SET_PDF_READY(state, payload) {
    state.pdf_ready = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
