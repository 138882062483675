<template>
  <div :class="['text-center py-16 fs-4', `bg-${bg}`]">
    <div class="mb-8">
      <img class="img-fluid" :src="img" :alt="imgDescription" />
    </div>
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    bg: {
      type: String,
      default: 'transparent',
    },
    img: {
      type: String,
      default: process.env.BASE_URL + 'assets/media/illustrations/oc-error.svg',
    },
    imgDescription: {
      type: String,
      default: 'Placeholder',
    },
    text: {
      type: String,
      default: 'No data to show',
    },
  },
};
</script>

<style scoped>
img {
  max-width: 12rem;
}
</style>
