import store from '@/store';

export default function () {
  const projectData = store.getters['projectPreview/projectData'];
  const params = new URLSearchParams(window.location.search);
  const url = window.location.href;
  const secret = url.split('/').pop();

  if (projectData || params.get('code')) {
    return {
      invalid: false,
    };
  } else {
    return {
      invalid: true,
      redirect: {
        name: 'single-project-code',
        query: {
          secret,
        },
      },
    };
  }
}
