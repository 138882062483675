import ApiService from '@/utils/api.service';

import { PublicProjectService } from '@/utils/storage.service';

import Project from '@/api/Project';
import Proposal from '@/api/Proposal';

const state = {
  projectData: null,
  paymentData: null,
  swimmingPoolItems: [],
  woodworkingItems: [],
  landscapingItems: [],
  changeOrderItems: [],
  currentScopeTypeItems: [],
  currentProposal: null,
  currentScopeType: null,
  code: null,
  tabsMenu: [],
  message: null,

  pdf_file: null,
  pdf_ready: false,
};

const getters = {
  projectData(state) {
    return state.projectData;
  },
  paymentData(state) {
    return state.paymentData;
  },
  swimmingPoolItems(state) {
    return state.swimmingPoolItems;
  },
  woodworkingItems(state) {
    return state.woodworkingItems;
  },
  landscapingItems(state) {
    return state.landscapingItems;
  },
  changeOrderItems(state) {
    return state.changeOrderItems;
  },
  currentScopeTypeItems(state) {
    return state.currentScopeTypeItems;
  },
  currentProposal(state) {
    return state.currentProposal;
  },
  currentScopeType(state) {
    return state.currentScopeType;
  },
  code(state) {
    return state.code;
  },
  tabsMenu(state) {
    return state.tabsMenu;
  },
  message(state) {
    return state.message;
  },

  pdfFile(state) {
    return state.pdf_file;
  },
  pdfReady(state) {
    return state.pdf_ready;
  },
};

const actions = {
  async initOverall({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.secret = params.secret || null;
      params.code = params.code || null;

      if (typeof params.current !== 'undefined' || typeof params.archive !== 'undefined') params.where = {};
      if (typeof params.current !== 'undefined') {
        params.where.current = params.current;
        delete params.current;
      }
      if (typeof params.archive !== 'undefined') {
        params.where.archive = params.archive;
        delete params.archive;
      }

      await ApiService.setHeader();

      const project = await Project.bySecret(params);
      const payment = await Project.bySecretSigned(params);
      const projectData = await project?.data?.data;
      const paymentData = await payment?.data?.data;
      const swimmingPoolItems = await projectData.attributes.proposal_scopes.data.filter(
        (el) => el.attributes.scope_type === 'Swimming Pool'
      );
      const landscapingItems = await projectData.attributes.proposal_scopes.data.filter(
        (el) => el.attributes.scope_type === 'Landscaping'
      );
      const woodworkingItems = await projectData.attributes.proposal_scopes.data.filter(
        (el) => el.attributes.scope_type === 'Woodworking'
      );
      const changeOrderItems = await projectData.attributes.proposal_scopes.data.filter(
        (el) => el.attributes.scope_type === 'Change Order'
      );
      const currentProposal = () => {
        if (swimmingPoolItems.length) {
          commit('SET_CURRENT_SCOPE_TYPE', 'Swimming Pool');
          commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...swimmingPoolItems]);

          return { ...swimmingPoolItems[0] };
        } else if (landscapingItems.length) {
          commit('SET_CURRENT_SCOPE_TYPE', 'Landscaping');
          commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...landscapingItems]);

          return { ...landscapingItems[0] };
        } else if (woodworkingItems.length) {
          commit('SET_CURRENT_SCOPE_TYPE', 'Woodworking');
          commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...woodworkingItems]);

          return { ...woodworkingItems[0] };
        } else if (changeOrderItems.length) {
          commit('SET_CURRENT_SCOPE_TYPE', 'Change Order');
          commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...changeOrderItems]);

          return { ...changeOrderItems[0] };
        } else {
          commit('SET_CURRENT_SCOPE_TYPE', null);
          commit('SET_CURRENT_SCOPE_TYPE_ITEMS', []);

          return null;
        }
      };
      const tabsMenu = [];
      if (swimmingPoolItems.length) {
        tabsMenu.push({
          id: '1001',
          title: 'Swimming Pool',
          scope_type: 'Swimming Pool',
        });
      }
      if (landscapingItems.length) {
        tabsMenu.push({
          id: '1002',
          title: 'Landscaping',
          scope_type: 'Landscaping',
        });
      }
      if (woodworkingItems.length) {
        tabsMenu.push({
          id: '1003',
          title: 'Woodwork',
          scope_type: 'Woodworking',
        });
      }
      if (changeOrderItems.length) {
        tabsMenu.push({
          id: '1004',
          title: 'Change Orders',
          scope_type: 'Change Order',
        });
      }

      commit('SET_TABS_MENU', tabsMenu);
      commit('SET_CODE', params.code);
      commit('SET_PROJECT_DATA', projectData);
      commit('SET_PAYMENT_DATA', paymentData);
      commit('SET_SWIMMING_POOL_ITEMS', [...swimmingPoolItems]);
      commit('SET_LANDSCAPING_ITEMS', [...landscapingItems]);
      commit('SET_WOODWORKING_ITEMS', [...woodworkingItems]);
      commit('SET_CHANGE_ORDER_ITEMS', [...changeOrderItems]);
      commit('SET_CURRENT_PROPOSAL', currentProposal());

      if (!PublicProjectService.exists(params.secret) && PublicProjectService.getToken(params.secret) !== params.code) {
        PublicProjectService.saveToken(params.secret, params.code);
      }

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async makePdf({ commit }, id) {
    try {
      const res = await Proposal.makePdf(id);

      await commit('SET_MESSAGE', res);
      await commit('SET_PDF_FILE', res.data.pdf_file);
      await commit('SET_PDF_READY', true);

      return res;
    } catch (e) {
      throw new Error(e);
    }
  },
  changeCurrentScopeTypeItems({ commit, getters }, scopeType) {
    if (scopeType === 'Swimming Pool') {
      commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...getters.swimmingPoolItems]);
    } else if (scopeType === 'Landscaping') {
      commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...getters.landscapingItems]);
    } else if (scopeType === 'Woodworking') {
      commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...getters.woodworkingItems]);
    } else if (scopeType === 'Change Order') {
      commit('SET_CURRENT_SCOPE_TYPE_ITEMS', [...getters.changeOrderItems]);
    } else {
      commit('SET_CURRENT_SCOPE_TYPE_ITEMS', []);
    }
  },
};

const mutations = {
  SET_PROJECT_DATA(state, payload) {
    state.projectData = payload;
  },
  SET_PAYMENT_DATA(state, payload) {
    state.paymentData = payload;
  },
  SET_SWIMMING_POOL_ITEMS(state, payload) {
    state.swimmingPoolItems = payload;
  },
  SET_WOODWORKING_ITEMS(state, payload) {
    state.woodworkingItems = payload;
  },
  SET_LANDSCAPING_ITEMS(state, payload) {
    state.landscapingItems = payload;
  },
  SET_CHANGE_ORDER_ITEMS(state, payload) {
    state.changeOrderItems = payload;
  },
  SET_CURRENT_SCOPE_TYPE_ITEMS(state, payload) {
    state.currentScopeTypeItems = payload;
  },
  SET_CURRENT_PROPOSAL(state, payload) {
    state.currentProposal = payload;
  },
  SET_CURRENT_SCOPE_TYPE(state, payload) {
    state.currentScopeType = payload;
  },
  SET_CODE(state, payload) {
    state.code = payload;
  },
  SET_TABS_MENU(state, payload) {
    state.tabsMenu = payload;
  },
  SET_MESSAGE(state, payload) {
    state.message = payload;
  },

  SET_PDF_READY(state, payload) {
    state.pdf_ready = payload;
  },
  SET_PDF_FILE(state, payload) {
    state.pdf_file = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
