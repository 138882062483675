export default [
  {
    path: '/',
    component: () => import('@/views/auth/PxvLogin'),
    meta: { title: 'Sign in' },
    name: 'login',
  },
  {
    path: '/single-project-code',
    component: () => import('@/views/auth/PxvProjectCode'),
    meta: { title: 'Project Code' },
    name: 'single-project-code',
  },
];
