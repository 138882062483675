import authenticated from '@/router/middlewares/authenticated';
import unAuthenticated from '@/router/middlewares/unAuthenticated';
import hasCode from '@/router/middlewares/hasCode';
import checkUserId from '@/router/middlewares/checkUserId';
import checkIfDesignerRoleOnly from '@/router/middlewares/checkIfDesignerRoleOnly';
import redirectDesignerToProporsals from '@/router/middlewares/redirectDesignerToProporsals';

export default {
  authenticated,
  unAuthenticated,
  hasCode,
  checkUserId,
  checkIfDesignerRoleOnly,
  redirectDesignerToProporsals,
};
