import ApiService from '@/utils/api.service';

const ProposalScopeTemplates = {
  index(data) {
    return ApiService.get(`proposal_scope_templates`, data);
  },
  create(data) {
    return ApiService.post(`proposal_scope_templates`, data);
  },
  show(id) {
    return ApiService.get(`proposal_scope_templates/${id}`);
  },
  single(id) {
    return ApiService.get(`proposal_scope_templates/${id}`);
  },
  update(data) {
    return ApiService.put(`proposal_scope_templates/${data.id}`, data.attributes);
  },
  delete(id) {
    return ApiService.delete(`proposal_scope_templates/${id}`);
  },
};

export default ProposalScopeTemplates;
