import { PublicProjectService } from '@/utils/storage.service';

import Project from '@/api/Project';

const state = {
  proposals: [],
  changeOrders: [],
  item: null,
  proposalsList: [],
  pagination: {},
  message: null,
  code: null,
  projectData: null,
  names: [],
  map_list: [],
  statistics: null,
};

const getters = {
  proposals(state) {
    return state.proposals;
  },
  changeOrders(state) {
    return state.changeOrders;
  },
  item(state) {
    return state.item;
  },
  proposalsList(state) {
    return state.proposalsList;
  },
  message(state) {
    return state.message;
  },
  code(state) {
    return state.code;
  },
  projectData(state) {
    return state.projectData;
  },
  names(state) {
    return state.names;
  },
  mapList(state) {
    return state.map_list;
  },
  statistics(state) {
    return state.statistics;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {};
      params.where.hidden = false;
      if (params.fields) params.fields = params.fields || '';
      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;
      if (typeof params.manager !== 'undefined') params.where.manager_id = params.manager;
      delete params.manager;
      if (typeof params.designer_ids !== 'undefined') params.where.designer_ids = params.designer_ids;
      delete params.designer_ids;

      const f = params.filters;
      if (f != undefined) delete params.filters;
      if (f) {
        if (f.query) params.query = f.query;
        if (Array.isArray(f.scope_type) && f.scope_type.length) params.where.scope_types = f.scope_type;
        if (f.city) params.where.city = { like: `%${f.city}%` };
        if (f.created_from) {
          params.where.created_at = { gte: f.created_from };
        }
        if (f.created_to) {
          params.where.created_at = f.created_from ? { gte: f.created_from, lte: f.created_to } : { lte: f.created_to };
        }

        if (f.updated_from) {
          params.where.updated_at = { gte: f.updated_from };
        }
        if (f.updated_to) {
          params.where.updated_at = f.updated_from ? { gte: f.updated_from, lte: f.updated_to } : { lte: f.updated_to };
        }

        if (f.cost_from) {
          params.where.total = { gte: f.cost_from };
        }
        if (f.cost_to) {
          params.where.total = f.cost_from ? { gte: f.cost_from, lte: f.cost_to } : { lte: f.cost_to };
        }
      }

      const dataProposals = await Project.index({ params, state: 'Proposals' });
      await commit('SET_PROPOSALS', dataProposals.data);

      // const dataChangeOrders = await Project.index({ params, state: 'Change Order' });
      // await commit('SET_CHANGE_ORDERS', dataChangeOrders.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async projectByCode({ commit }, data) {
    try {
      const projectData = await Project.checkCode(data);

      if (!PublicProjectService.exists(data?.secret) && PublicProjectService.getToken(data?.secret) !== data?.code) {
        PublicProjectService.saveToken(data?.secret, data?.code);
      }

      commit('SET_CODE', data?.code || null);
      commit('SET_PROJECT_DATA', projectData?.data?.data);

      return true;
    } catch {
      return false;
    }
  },
  async proposalsList({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = 10000;
      params.page = 1;
      params.order = 'desc';
      params.order_by = 'sid';
      params.where = {};
      params.where.hidden = false;

      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;

      const data = await Project.index({ params, state: 'Proposal' });

      await commit('SET_PROPOSALS_LIST', data?.data?.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async single(_, id) {
    try {
      return await Project.single(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async names({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = 10000;
      params.page = 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {};
      params.where.hidden = false;
      if (typeof params.current !== 'undefined') params.where.current = params.current;
      delete params.current;
      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;
      if (typeof params.manager_id !== 'undefined') params.where.manager_id = params.manager_id;
      delete params.manager_id;
      if (typeof params.status !== 'undefined') params.where.status = params.status;
      delete params.status;

      const data = await Project.names({ params });

      await commit('SET_NAMES', data.data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async mapList({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = 10000;
      params.page = 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {};
      params.where.hidden = false;
      if (typeof params.current !== 'undefined') params.where.current = params.current;
      delete params.current;
      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;
      if (typeof params.manager !== 'undefined') params.where.manager_id = params.manager;
      delete params.manager;

      const data = await Project.mapList({ params });

      await commit('SET_MAP_LIST', data.data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async statistics({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.where = {};
      params.where.hidden = false;

      if (typeof params.archive !== 'undefined') {
        params.where.archive = params.archive;
        delete params.archive;
      }

      const data = await Project.statistics({ params });

      await commit('SET_STATISTICS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async show({ commit }, id) {
    try {
      const data = await Project.single(id);

      await commit('SET_ITEM', data?.data?.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async create({ commit }, payload) {
    try {
      const data = await Project.create(payload);

      await commit('SET_MESSAGE', data);

      return data;
    } catch (e) {
      return e.response;
    }
  },
  async update({ commit }, { id, params = {} }) {
    try {
      const data = await Project.update(id, params);

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_PROPOSALS_LIST(state, payload) {
    state.proposalsList = payload;
  },
  SET_PROPOSALS(state, payload) {
    state.proposals = payload;
  },
  SET_CHANGE_ORDERS(state, payload) {
    state.changeOrders = payload;
  },
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_CODE(state, code) {
    state.code = code;
  },
  SET_PROJECT_DATA(state, payload) {
    state.projectData = payload;
  },
  SET_NAMES(state, payload) {
    state.names = payload;
  },
  SET_MAP_LIST(state, payload) {
    state.map_list = payload;
  },
  SET_STATISTICS(state, payload) {
    state.statistics = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
