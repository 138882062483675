import ApiService from '@/utils/api.service';

const Leads = {
  initOverall(params) {
    return ApiService.get(`leads`, params);
  },
  single(id) {
    return ApiService.get(`leads/${id}`);
  },
  create(data) {
    return ApiService.post(`leads`, data);
  },
  update({ id, attributes }) {
    return ApiService.put(`leads/${id}`, attributes);
  },
  delete(id) {
    return ApiService.delete(`leads/${id}`);
  },
};

export default Leads;
