const TOKEN_KEY = 'access_token';
const USER = 'zz';

const TokenService = {
  getToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  },
  saveToken(token) {
    window.localStorage.setItem(TOKEN_KEY, token);
  },
  saveUser(user) {
    let us = btoa(unescape(encodeURIComponent(JSON.stringify(user))));
    window.localStorage.setItem(USER, JSON.stringify(us));
  },

  exists() {
    return !!this.getToken() && !!this.getUser();
  },

  getUser() {
    let str = window.localStorage.getItem(USER);

    if (str) {
      let strlast = str.substring(0, str.length - 1);
      let resstr = strlast.substring(1);
      let data = JSON.parse(decodeURIComponent(escape(atob(resstr))));

      return data;
    }

    return '';
  },

  removeUser() {
    window.localStorage.removeItem(USER);
  },

  removeToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  },

  removeAll() {
    this.removeUser();
    this.removeToken();
  },

  clearStorage() {
    window.localStorage.clear();
  },
};

const PublicProjectService = {
  getToken(key) {
    return window.localStorage.getItem(key);
  },
  saveToken(key, token) {
    window.localStorage.setItem(key, token);
  },
  exists(key) {
    return !!this.getToken(key);
  },
  removeToken(key) {
    window.localStorage.removeItem(key);
  },
  clearStorage() {
    window.localStorage.clear();
  },
};

export { TokenService, PublicProjectService };
