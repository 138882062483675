import { GAUTH_KEY } from '@/shared/constants';

import Vue from 'vue';
import GAuth from 'vue-google-oauth2';

const gauthOption = {
  clientId: GAUTH_KEY,
  plugin_name: 'rsg-ca-site',
};
Vue.use(GAuth, gauthOption);
