<template>
  <input type="text" :value="model" @input="handleInput" />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    decimalScale: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      cleave: null,
      model: '',
    };
  },
  watch: {
    value(newValue) {
      this.cleave.setRawValue(newValue);
    },
  },
  mounted() {
    this.model = this.value;
    this.$nextTick(() => {
      // eslint-disable-next-line no-undef
      this.cleave = new Cleave(this.$el, {
        numeral: true,
        numeralDecimalScale: this.decimalScale,
        rawValueTrimPrefix: true,
        numeralThousandsGroupStyle: 'thousand',
      });
    });
  },
  methods: {
    handleInput() {
      setTimeout(() => {
        let val = this.cleave.getRawValue();
        // if (val.at(-1) == '.') val = Number(val);
        this.$emit('input', Number(val));
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped></style>
