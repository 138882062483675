import Proposal from '@/api/Proposal';
import ProposalScopeTemplates from '@/api/ProposalScopeTemplates';

const state = {
  proposals: [],
  proposalScopeTemplateNames: [],
  changeOrders: [],
  message: null,
  templates: {
    swimmingPool: {
      introduction: `<p>Thank you for the opportunity to submit a proposal for your project. At Royal Stone, we pride ourselves on delivering a quality product and enjoyable experience. We have a unique ability to provide you with a full-service outdoor construction solution. This Proposal (“Contract”) is made and entered into by and between Royal Stone Landscaping and Design Limited (“Royal Stone” or “Contractor”), and the homeowner that is executing this Contract (hereinafter “Customer”) with Royal Stone.</p>
        <h3>We are pleased to submit the following proposal to supply and install the following landscaping:</h3>
        <p>Hardscape bases as per these guidelines:</p>
        <ul>
          <li>Supply and install 3-5 inches of aggregate base over existing subgrade</li>
          <li>Supply and install 1-3 inches of bedding sand</li>
          <li>Square footage quoted for all interlock products includes 10% waste</li>
          <li>Square footage quoted for all natural stone products includes 15% waste</li>
        </ul>
        <p>Please note:</p>
        <ul>
          <li>Proposal does not include the cost of utilities, including gas, plumbing, electrical, trenching, or any other unless listed.</li>
          <li>Proposal does not include the cost of restorations to any affected areas unless listed.</li>
        </ul>`,
      specialNotes: `<p>Lorem Ipsum</p>`,
    },
  },
  currentProposal: null,
  currentProposalId: null,
  names: [],
  statements: null,

  showEquipments: true,
  showInclusions: true,
  showIntroduction: true,
  showPaymentDetails: true,
  showPricing: true,
  showPlantings: true,
  showSpecialNotes: true,
  showSpecifications: true,
  showTerms: true,
};

const getters = {
  proposals(state) {
    return state.proposals;
  },
  proposalScopeTemplateNames(state) {
    return state.proposalScopeTemplateNames;
  },
  changeOrders(state) {
    return state.changeOrders;
  },
  message(state) {
    return state.message;
  },
  swimmingPoolIntroductionTemplate(state) {
    return state.templates.swimmingPool.introduction;
  },
  swimmingPoolSpecialNotesTemplate(state) {
    return state.templates.swimmingPool.specialNotes;
  },
  currentProposal(state) {
    return state.currentProposal;
  },
  currentProposalId(state) {
    return state.currentProposalId;
  },
  names(state) {
    return state.names;
  },
  statements(state) {
    return state.statements;
  },

  showEquipments(state) {
    return state.showEquipments;
  },
  showInclusions(state) {
    return state.showInclusions;
  },
  showIntroduction(state) {
    return state.showIntroduction;
  },
  showPaymentDetails(state) {
    return state.showPaymentDetails;
  },
  showPricing(state) {
    return state.showPricing;
  },
  showPlantings(state) {
    return state.showPlantings;
  },
  showSpecialNotes(state) {
    return state.showSpecialNotes;
  },
  showSpecifications(state) {
    return state.showSpecifications;
  },
  showTerms(state) {
    return state.showTerms;
  },
};

const actions = {
  async initProposals({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.fields) params.fields = params.fields || '';
      if (
        params.subject_id ||
        params.state ||
        typeof params.current !== 'undefined' ||
        typeof params.manager !== 'undefined' ||
        typeof params.designer_ids !== 'undefined' ||
        typeof params.archive !== 'undefined' ||
        typeof params.status !== 'undefined' ||
        typeof params._not !== 'undefined'
      )
        params.where = {};
      if (params.subject_id) params.where.subject_id = params.subject_id;
      delete params.subject_id;
      if (params.state) params.where.state = params.state;
      delete params.state;
      if (typeof params.current !== 'undefined') params.where.current = params.current;
      delete params.current;
      if (typeof params.manager !== 'undefined') params.where.manager_id = params.manager;
      delete params.manager;
      if (typeof params.designer_ids !== 'undefined') params.where.designer_ids = params.designer_ids;
      delete params.designer_ids;
      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;
      if (typeof params.status !== 'undefined') params.where.status = params.status;
      delete params.status;
      if (typeof params._not !== 'undefined') params.where._not = params._not;
      delete params._not;

      const f = params.filters;
      if (f != undefined) delete params.filters;
      if (f) {
        if (f.query) params.query = f.query;
        if (Array.isArray(f.scope_type) && f.scope_type.length) params.where.scope_type = f.scope_type;
        if (f.city) params.where.city = { like: `%${f.city}%` };
        if (f.created_from) {
          params.where.created_at = { gte: f.created_from };
        }
        if (f.created_to) {
          params.where.created_at = f.created_from ? { gte: f.created_from, lte: f.created_to } : { lte: f.created_to };
        }

        if (f.updated_from) {
          params.where.updated_at = { gte: f.updated_from };
        }
        if (f.updated_to) {
          params.where.updated_at = f.updated_from ? { gte: f.updated_from, lte: f.updated_to } : { lte: f.updated_to };
        }

        if (f.cost_from) {
          params.where.sub_total = { gte: f.cost_from };
        }
        if (f.cost_to) {
          params.where.sub_total = f.cost_from ? { gte: f.cost_from, lte: f.cost_to } : { lte: f.cost_to };
        }
      }
      const data = await Proposal.index({ params });

      await commit('SET_PROPOSALS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async proposalScopeTemplateNames({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10000;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.fields) params.fields = params.fields || '';

      const data = await ProposalScopeTemplates.index({ params });

      await commit('SET_PROPOSAL_SCOPE_TEMPLATE_NAMES', data.data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async initChangeOrders({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.subject_id || params.state || typeof params.archive !== 'undefined') params.where = {};
      if (params.subject_id) params.where.subject_id = params.subject_id;
      delete params.subject_id;
      if (params.state) params.where.state = params.state;
      delete params.state;
      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;

      const data = await Proposal.index({ params });

      await commit('SET_CHANGE_ORDERS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async names({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (typeof params.subject_id !== 'undefined' || typeof params.status !== 'undefined') params.where = {};
      if (typeof params.subject_id !== 'undefined') params.where.subject_id = params.subject_id;
      delete params.subject_id;
      if (typeof params.status !== 'undefined') params.where.status = params.status;
      delete params.status;

      const data = await Proposal.names({ params });

      await commit('SET_NAMES', data.data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async statements({ commit }, id) {
    try {
      const newStatements = await Proposal.statements(id);

      await commit('SET_STATEMENTS', newStatements);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getAllById(_, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (
        params.subject_id ||
        params.state ||
        params.scope_type ||
        typeof params.archive !== 'undefined' ||
        typeof params.status !== 'undefined' ||
        typeof params.current !== 'undefined'
      )
        params.where = {};
      if (params.scope_type) params.where.scope_type = params.scope_type;
      delete params.scope_type;
      if (params.subject_id) params.where.subject_id = params.subject_id;
      delete params.subject_id;
      if (params.state) params.where.state = params.state;
      delete params.state;
      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;
      if (typeof params.status !== 'undefined') params.where.status = params.status;
      delete params.status;
      if (typeof params.current !== 'undefined') params.where.current = params.current;
      delete params.current;

      const data = await Proposal.index({ params });

      return data.data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getAllByParentId(_, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.parent_id || params.scope_type) params.where = {};
      if (params.parent_id) params.where._or = [{ parent_id: params.parent_id }, { id: params.parent_id }];
      delete params.parent_id;
      if (params.scope_type) params.where.scope_type = params.scope_type;
      delete params.scope_type;

      const data = await Proposal.index({ params });

      return data.data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async create({ commit }, payload) {
    try {
      const data = await Proposal.create(payload);

      await commit('SET_MESSAGE', data);

      return data.data.data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async show({ commit }, id) {
    try {
      const data = await Proposal.single(id);

      await commit('SET_SHOW_EQUIPMENTS', data.data.data.attributes.show_equipments);
      await commit('SET_SHOW_INCLUSIONS', data.data.data.attributes.show_inclusions);
      await commit('SET_SHOW_INTRODUCTION', data.data.data.attributes.show_introduction);
      await commit('SET_SHOW_PAYMENT_DETAILS', data.data.data.attributes.show_payment_details);
      await commit('SET_SHOW_PRICING', data.data.data.attributes.show_pricing);
      await commit('SET_SHOW_PLANTINGS', data.data.data.attributes.show_plantings);
      await commit('SET_SHOW_SPECIAL_NOTES', data.data.data.attributes.show_special_notes);
      await commit('SET_SHOW_SPECIFICATIONS', data.data.data.attributes.show_specifications);
      await commit('SET_SHOW_TERMS', data.data.data.attributes.show_terms);

      return data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async single({ commit }, { id, params = {} }) {
    try {
      if (params.fields) params.fields = params.fields || '';

      const data = await Proposal.single(id, { params });
      const attributes = await data.data.data.attributes;

      await commit('SET_SHOW_EQUIPMENTS', attributes.show_equipments);
      await commit('SET_SHOW_INCLUSIONS', attributes.show_inclusions);
      await commit('SET_SHOW_INTRODUCTION', attributes.show_introduction);
      await commit('SET_SHOW_PAYMENT_DETAILS', attributes.show_payment_details);
      await commit('SET_SHOW_PRICING', attributes.show_pricing);
      await commit('SET_SHOW_PLANTINGS', attributes.show_plantings);
      await commit('SET_SHOW_SPECIAL_NOTES', attributes.show_special_notes);
      await commit('SET_SHOW_SPECIFICATIONS', attributes.show_specifications);
      await commit('SET_SHOW_TERMS', attributes.show_terms);

      return data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async getCurrentState(_, { id, params = {} }) {
    try {
      if (params.fields) params.fields = params.fields || '';

      const data = await Proposal.single(id, { params });

      return await data.data.data.attributes;
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const data = await Proposal.update({
        id: payload.id,
        ...payload,
      });

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      return e.response;
    }
  },
  async bulkUpdate({ commit }, payload) {
    try {
      const data = await Proposal.bulkUpdate(payload);

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async megaUpdate({ commit }, payload) {
    try {
      const data = await Proposal.megaUpdate(payload);
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async createNewVersion(_, id) {
    try {
      return await Proposal.createNewVersion(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async duplicate({ commit }, id) {
    try {
      const data = await Proposal.duplicate(id);

      await commit('SET_MESSAGE', data);

      return data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async saveAsATemplate({ commit }, data) {
    try {
      const req = await Proposal.saveAsTemplate(data);

      await commit('SET_MESSAGE', req);

      return req;
    } catch (e) {
      throw new Error(e);
    }
  },

  async uploadSignedPdfFile({ commit }, data) {
    try {
      const req = await Proposal.uploadSignedPdfFile(data);

      await commit('SET_MESSAGE', req);

      return req;
    } catch (e) {
      throw new Error(e);
    }
  },
  async removeSignedPdfFile({ commit }, id) {
    try {
      const req = await Proposal.removeSignedPdfFile(id);

      await commit('SET_MESSAGE', req);

      return req;
    } catch (e) {
      throw new Error(e);
    }
  },

  markAsShared({ commit, getters }, data) {
    const proposals = [...getters.proposals.data];
    const newProposals = proposals.map((el) => {
      let foundedProposal = data.find((elJ) => elJ.id === el.id);

      if (foundedProposal) {
        el = {
          ...el,
          attributes: {
            ...el,
            pdf_file: foundedProposal.pdf_file,
            status: 'Shared',
          },
        };
      }

      return el;
    });

    commit('SET_PROPOSALS', {
      data: newProposals,
      meta: getters.proposals.meta,
    });
  },
  markAsSentToSign({ commit, getters }, id) {
    const proposals = [...getters.proposals.data];
    const newProposals = proposals.map((el) => {
      if (el.id === id) {
        el = {
          ...el,
          attributes: {
            ...el,
            status: 'Sent To Sign',
          },
        };
      }

      return el;
    });

    commit('SET_PROPOSALS', {
      data: newProposals,
      meta: getters.proposals.meta,
    });
  },
};

const mutations = {
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_PROPOSALS(state, payload) {
    state.proposals = payload;
  },
  SET_PROPOSAL_SCOPE_TEMPLATE_NAMES(state, payload) {
    state.proposalScopeTemplateNames = payload;
  },
  SET_CHANGE_ORDERS(state, payload) {
    state.changeOrders = payload;
  },
  SET_CURRENT_PROPOSAL(state, payload) {
    state.currentProposal = payload;
  },
  SET_CURRENT_PROPOSAL_ID(state, payload) {
    state.currentProposalId = payload;
  },
  SET_NAMES(state, payload) {
    state.names = payload;
  },
  SET_STATEMENTS(state, payload) {
    state.statements = payload;
  },

  SET_SHOW_EQUIPMENTS(state, payload) {
    state.showEquipments = payload;
  },
  SET_SHOW_INCLUSIONS(state, payload) {
    state.showInclusions = payload;
  },
  SET_SHOW_INTRODUCTION(state, payload) {
    state.showIntroduction = payload;
  },
  SET_SHOW_PAYMENT_DETAILS(state, payload) {
    state.showPaymentDetails = payload;
  },
  SET_SHOW_PRICING(state, payload) {
    state.showPricing = payload;
  },
  SET_SHOW_PLANTINGS(state, payload) {
    state.showPlantings = payload;
  },
  SET_SHOW_SPECIAL_NOTES(state, payload) {
    state.showSpecialNotes = payload;
  },
  SET_SHOW_SPECIFICATIONS(state, payload) {
    state.showSpecifications = payload;
  },
  SET_SHOW_TERMS(state, payload) {
    state.showTerms = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
