import Leads from '@/api/Leads';

const state = {
  leads: [],
  leadsMinified: [],
  message: null,
};

const getters = {
  leads(state) {
    return state.leads;
  },
  leadsMinified(state) {
    return state.leadsMinified;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async initOverall({ commit }, params = {}) {
    try {
      if (params.fields) params.fields = params.fields || '';
      const data = await Leads.initOverall({ params });

      await commit('SET_LEADS', data.data.data);

      return true;
    } catch (e) {
      await commit('SET_LEADS', []);
    }
  },
  async initLeadsMinified({ commit }, params = {}) {
    try {
      if (params.fields) params.fields = params.fields || '';
      const data = await Leads.initOverall({ params });

      await commit('SET_LEADS_MINIFIED', data.data.data);

      return true;
    } catch (e) {
      await commit('SET_LEADS_MINIFIED', []);
    }
  },
  async create({ commit }, payload) {
    try {
      const data = await Leads.create(payload);

      await commit('SET_MESSAGE', data);

      return data.data.data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async single(_, id) {
    try {
      return await Leads.single(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const updatedLead = await Leads.update(payload);

      await commit('SET_MESSAGE', updatedLead);

      return updatedLead.data.data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async delete({ commit }, id) {
    try {
      const data = await Leads.delete(id);

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_LEADS(state, payload) {
    state.leads = payload;
  },
  SET_LEADS_MINIFIED(state, payload) {
    state.leadsMinified = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
