import DashboardStatistics from '@/api/DashboardStatistics';

const state = {
  statistics: null,
  message: null,
};

const getters = {
  statistics(state) {
    return state.statistics;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async initOverall({ commit }, params = {}) {
    try {
      const data = await DashboardStatistics.initOverall({ params });

      await commit('SET_STATISTICS', data.data);

      return true;
    } catch (e) {
      await commit('SET_STATISTICS', {});
    }
  },
};

const mutations = {
  SET_STATISTICS(state, payload) {
    state.statistics = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
