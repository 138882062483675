import ApiService from '@/utils/api.service';

const PricingItem = {
  index(data) {
    return ApiService.get(`pricing_items`, data);
  },
  create(data) {
    return ApiService.post(`pricing_items`, data);
  },
  show(id) {
    return ApiService.get(`pricing_items/${id}`);
  },
  update(data) {
    return ApiService.put(`pricing_items/${data.id}`, data.attributes);
  },
  delete(id) {
    return ApiService.delete(`pricing_items/${id}`);
  },
};

export default PricingItem;
