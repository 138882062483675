<template>
  <div v-bind="$attrs">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => KTDrawer.createInstances());
  },
};
</script>
