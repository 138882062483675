<template>
  <v-popover
    :class="[popoverExtendedClass]"
    popoverClass="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary d-block py-3"
    openClass="show"
  >
    <slot v-if="isDefaultBtnHidden" name="btn" role="button" />
    <button :class="btnClasses" type="button">
      <i v-if="!isIconHidden" :class="[iconClasses, 'pe-0']" />
      <span v-if="title">{{ title }}</span>
    </button>

    <template #popover>
      <div class="menu-item px-3" v-for="item in items" :key="item.title">
        <button
          :class="[
            'menu-link btn btn-flex justify-content-between fs-7 w-100 px-3 py-2',
            { 'disabled text-muted': item.disabled },
          ]"
          type="button"
          :disabled="item.disabled || item.inProcess"
          @click.prevent="() => onClick(item.action)"
          v-close-popover="item.inProcess"
        >
          <span>{{ item.title }}</span>
          <span v-if="item.inProcess" class="spinner-border spinner-border-sm" />
        </button>
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    isDefaultBtnHidden: {
      type: Boolean,
      default: false,
    },
    isIconHidden: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    popoverExtendedClass: {
      type: String,
    },
    btnClasses: {
      type: String,
      default: 'tooltip-target btn btn-flex btn-icon w-30px h-30px p-0 text-hover-primary text-gray-400',
    },
    iconClasses: {
      type: String,
      default: 'fa-solid fa-ellipsis-vertical text-inherit',
    },
  },
  methods: {
    async onClick(action) {
      await action();
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  .tooltip-inner {
    padding: 0;
    box-shadow: none !important;
  }
}

.popover {
  max-width: 200px !important;
}

.tooltip-inner {
  max-width: none !important;
}
</style>

<style lang="scss" scoped>
.menu-link.disabled {
  &:hover {
    cursor: default;
  }
}
</style>
