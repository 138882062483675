import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

// Directives
import './directives';

// Register Globals
import '@/plugins/globals';
import '@/plugins/google-auth2';
import '@/plugins/v-money';
import '@/plugins/sweetalert2';
import '@/plugins/vuelidate';
import '@/plugins/inline-svg';
import '@/plugins/v-tooltip';
import '@/plugins/vue-googlemaps';
import '@/plugins/vue-moment';
import '@/plugins/vue-scrollto';
import '@/plugins/vue-scrollactive';
import '@/plugins/vue-clipboard2';
import '@/plugins/vue-numeric';
import '@/plugins/vue-the-mask';
import '@/plugins/vue-flatpickr';
import '@/plugins/vue-textarea-autosize';
import i18n from '@/plugins/vue-i18n';

// Plugins
import '@/plugins/portal-vue';

// Icons & Fonts
import '@fortawesome/fontawesome-free/css/all.min.css';

// Global Styles
import '@/assets/sass/plugins.scss';
import '@/assets/sass/style.scss';

Vue.config.productionTip = false;

// Some services
import { TokenService } from '@/utils/storage.service';

import ApiService from '@/utils/api.service';

// SET THE BASE_URL OF THE API
ApiService.init(process.env.VUE_APP_API_BASE_URL);
ApiService.mount401Interceptor();

// IF TOKEN EXISTS SET HEADER
if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mount401Interceptor();
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount('#app');
