import ApiService from '@/utils/api.service';

const PlantingSections = {
  index(data) {
    return ApiService.get(`planting_sections`, data);
  },
  create(data) {
    return ApiService.post(`planting_sections`, data);
  },
  show(id) {
    return ApiService.get(`planting_sections/${id}`);
  },
  update(data) {
    return ApiService.put(`planting_sections/${data.id}`, data.attributes);
  },
  delete(id) {
    return ApiService.delete(`planting_sections/${id}`);
  },
};

export default PlantingSections;
