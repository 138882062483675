import ApiService from '@/utils/api.service';

const PlantingItemTemplate = {
  index(data) {
    return ApiService.get(`planting_item_templates`, data);
  },
  create(data) {
    return ApiService.post(`planting_item_templates`, data);
  },
  show(id) {
    return ApiService.get(`planting_item_templates/${id}`);
  },
  single(id) {
    return ApiService.get(`planting_item_templates/${id}`);
  },
  update(data) {
    return ApiService.put(`planting_item_templates/${data.id}`, data.attributes);
  },
  delete(id) {
    return ApiService.delete(`planting_item_templates/${id}`);
  },
};

export default PlantingItemTemplate;
