<template>
  <div :class="['d-flex align-items-center', extendedClass]">
    <div :class="['rounded-pill d-flex text-white py-2 px-4 fs-8', progressBgClass]" style="width: 214px">
      <strong class="fw-medium me-3">{{ title }}</strong>
      <span :class="['opacity-75', valueTextClass]">{{ value }}</span>
    </div>
    <strong v-if="sideText" :class="['fs-7 ms-2', sideTextClass]">{{ sideText }}</strong>
  </div>
</template>

<script>
export default {
  props: {
    extendedClass: {
      type: [String, Array],
      default: '',
    },
    progressBgClass: {
      type: String,
      default: 'bg-primary',
    },
    valueTextClass: {
      type: String,
      default: '',
    },
    sideTextClass: {
      default: 'text-gray-800',
    },
    title: {
      type: String,
    },
    value: {
      type: String,
      required: true,
    },
    sideText: {
      type: [String, Number],
    },
  },
};
</script>
