<template>
  <router-link v-if="to" :class="['d-flex align-items-center', extendedClass]" :to="to">
    <span v-if="svgIcon" class="svg-icon svg-icon-5 me-2">
      <inline-svg :src="svgIcon" />
    </span>
    <i v-if="fontIcon" :class="['me-2', fontIcon]" />
    {{ title }}
  </router-link>
  <div v-else :class="['d-flex align-items-center', extendedClass]">
    <span v-if="svgIcon" class="svg-icon svg-icon-5 me-2">
      <inline-svg :src="svgIcon" />
    </span>
    <i v-if="fontIcon" :class="['me-2', fontIcon]" />
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    extendedClass: {
      type: [String, Array],
      default: '',
    },
    iconExtendedClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
    },
    to: {
      type: [String, Object],
    },
    svgIcon: {
      type: String,
    },
    fontIcon: {
      type: String,
    },
  },
};
</script>
