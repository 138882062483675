import ApiService from '@/utils/api.service';

const Payments = {
  initOverall(data) {
    return ApiService.get(`payments`, data);
  },
  single(id) {
    return ApiService.get(`payments/${id}`);
  },
  create(data) {
    return ApiService.post(`payments`, data);
  },
  update(data) {
    return ApiService.put(`payments/${data.id}`, data.attributes);
  },
  delete(id) {
    return ApiService.delete(`payments/${id}`);
  },
};

export default Payments;
