import Units from '@/api/Units';

const state = {
  units: [],
  message: null,
};

const getters = {
  units(state) {
    return state.units;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';

      if (params.where) params.where = params.where || {};

      const data = await Units.index({ params });

      await commit('SET_UNITS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async create({ commit }, name) {
    try {
      const data = await Units.create({ name });

      await commit('SET_MESSAGE', data);

      return data;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_UNITS(state, payload) {
    state.units = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
