import store from '@/store';
import router from '@/router/index';

export default async () => {
  const authUser = store.getters['auth/user'];
  const roles = authUser.attributes.groups.data;
  const hasExtendedRoles =
    !!roles.find((el) => el.attributes.name === 'Admin') ||
    !!roles.find((el) => el.attributes.name === 'Master') ||
    !!roles.find((el) => el.attributes.name === 'Manager');

  if (!hasExtendedRoles) await router.push({ name: 'dashboard' });

  return { invalid: false };
};
