export default [
  {
    path: '/proposals',
    component: () => import('@/views/proposals/PxvProposals'),
    meta: { title: 'Proposals' },
    name: 'proposals',
  },
  {
    path: '/proposals/archive',
    component: () => import('@/views/proposals/PxvProposalsArchive'),
    meta: { title: 'Archive' },
    name: 'proposals-archive',
  },
  {
    path: '/proposals/new',
    component: () => import('@/views/proposals/PxvCreateProposal'),
    meta: { title: 'New Project' },
    name: 'create-proposal',
  },
];
