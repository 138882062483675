export default [
  {
    path: 'contracts',
    component: () => import('@/views/projects-single/pages/PxvContracts'),
    meta: { title: 'Single Project Contracts' },
    name: 'projects-single.contracts',
  },
  {
    path: 'statements',
    component: () => import('@/views/projects-single/pages/PxvStatements'),
    meta: { title: 'Single Project Statements' },
    name: 'projects-single.statements',
  },
  {
    path: 'internal-notes',
    component: () => import('@/views/projects-single/pages/PxvInternalNotes'),
    meta: { title: 'Single Project Internal Notes' },
    name: 'projects-single.internal-notes',
  },
  {
    path: 'activity',
    component: () => import('@/views/projects-single/pages/PxvActivity'),
    meta: { title: 'Single Project Activity' },
    name: 'projects-single.activity',
  },
];
