<template>
  <input
    v-if="$attrs.type !== 'textarea'"
    v-bind="$attrs"
    :class="validateModel(state)"
    @input="updateValue($event.target.value)"
  />
  <textarea-autosize
    v-else
    v-bind="$attrs"
    :class="validateModel(state)"
    rows="1"
    :min-height="42"
    :max-height="500"
    @input="updateValue($event.target.value)"
  />
</template>
<script>
export default {
  props: {
    state: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);
    },
  },
};
</script>
