export default [
  {
    path: 'introduction',
    component: () => import('@/views/proposals/wizard/introduction/Introduction'),
    meta: { title: 'Proposal Introduction' },
    name: 'proposal-wizard.introduction',
  },
  {
    path: 'pricing',
    component: () => import('@/views/proposals/wizard/pricing/Pricing'),
    meta: { title: 'Proposal Pricing' },
    name: 'proposal-wizard.pricing',
  },
  {
    path: 'notes',
    component: () => import('@/views/proposals/wizard/notes/Notes'),
    meta: { title: 'Proposal Notes' },
    name: 'proposal-wizard.notes',
  },
  {
    path: 'considerations',
    component: () => import('@/views/proposals/wizard/inclusions/Inclusions'),
    meta: { title: 'Proposal Considerations' },
    name: 'proposal-wizard.considerations',
  },
  {
    path: 'payment-details',
    component: () => import('@/views/proposals/wizard/payment-details/PaymentDetails'),
    meta: { title: 'Proposal Payment Details' },
    name: 'proposal-wizard.payment-details',
  },
  {
    path: 'preview',
    component: () => import('@/views/proposals/wizard/preview/Preview'),
    meta: { title: 'Proposal Preview' },
    name: 'proposal-wizard.preview',
  },

  {
    path: 'specifications',
    component: () => import('@/views/proposals/wizard/specifications/Specifications'),
    meta: { title: 'Proposal Specifications' },
    name: 'proposal-wizard.specifications',
  },
  {
    path: 'equipment',
    component: () => import('@/views/proposals/wizard/equipment/Equipment'),
    meta: { title: 'Proposal Equipment' },
    name: 'proposal-wizard.equipment',
  },

  {
    path: 'planting',
    component: () => import('@/views/proposals/wizard/planting/Planting'),
    meta: { title: 'Proposal Planting' },
    name: 'proposal-wizard.planting',
  },
];
