import Vue from 'vue';
import VueGoogleMaps from 'vue-googlemaps';

import 'vue-googlemaps/dist/vue-googlemaps.css';

Vue.use(VueGoogleMaps, {
  load: {
    apiKey: process.env.VUE_APP_PLACE_API_KEY,
    libraries: ['places'],
    useBetaRenderer: false,
  },
});
