import ConstructionDetailsTemplates from '@/api/ConstructionDetailsTemplates';

const state = {
  items: [],
  message: null,
};

const getters = {
  items(state) {
    return state.items;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';

      const data = await ConstructionDetailsTemplates.index({ params });

      await commit('SET_ITEMS', data.data);

      return true;
    } catch (e) {
      await commit('SET_ITEMS', []);
    }
  },
};

const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
