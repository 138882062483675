import ApiService from '@/utils/api.service';

const AttachedSpecifications = {
  index(data) {
    return ApiService.get(`attached_specifications`, data);
  },
  single(id) {
    return ApiService.get(`attached_specifications/${id}`);
  },
  create(data) {
    return ApiService.post(`attached_specifications`, data);
  },
  update(data) {
    return ApiService.put(`attached_specifications/${data.id}`, data);
  },
  delete(id) {
    return ApiService.delete(`attached_specifications/${id}`);
  },
};

export default AttachedSpecifications;
