import ApiService from '@/utils/api.service';

const Adjustments = {
  initOverall(data) {
    return ApiService.get(`adjustments`, data);
  },
  single(id) {
    return ApiService.get(`adjustments/${id}`);
  },
  create(data) {
    return ApiService.post(`adjustments`, data);
  },
  update({ id, attributes }) {
    return ApiService.put(`adjustments/${id}`, attributes);
  },
  delete(id) {
    return ApiService.delete(`adjustments/${id}`);
  },
};

export default Adjustments;
