import MailTextTemplates from '@/api/MailTextTemplates';

const state = {
  mail_text_templates: [],
  message: null,
};

const getters = {
  mailTextTemplates(state) {
    return state.mail_text_templates;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';

      const data = await MailTextTemplates.index({ params });

      await commit('SET_MAIL_TEXT_TEMPLATES', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_MAIL_TEXT_TEMPLATES(state, payload) {
    state.mail_text_templates = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
