import store from '@/store';
import router from '@/router/index';

export default async () => {
  const authUser = store.getters['auth/user'];
  const getRoute = store.getters['auth/getRoute'];
  const hasMasterRole = !!authUser.attributes.groups.data.find((el) => el.attributes.name === 'Master');
  const currentProposal = await store.dispatch('proposals/single', {
    id: getRoute.params.id,
    params: {
      fields: 'subject',
    },
  });

  if (currentProposal.data.data.attributes.subject.data.attributes.manager.data.id !== authUser.id && !hasMasterRole)
    await router.push({ name: 'dashboard' });

  return { invalid: false };
};
