<template>
  <button :type="type" :class="[`btn btn-${color}`, size ? `btn-${size}` : '', extendedClasses]" v-bind="$attrs">
    <slot />
    <span v-if="inProcess" class="spinner-border spinner-border-sm ms-2" />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
    },
    extendedClasses: {
      type: String,
    },
    inProcess: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
