import Payments from '@/api/Payments';

const state = {
  payments: [],
  message: null,
};

const getters = {
  payments(state) {
    return state.payments;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async initOverall({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10000;
      if (params.fields) params.fields = params.fields || '';
      if (
        typeof params.subject_id !== 'undefined' ||
        typeof params.scope_type !== 'undefined' ||
        typeof params.manager !== 'undefined' ||
        typeof params.current !== 'undefined' ||
        typeof params.archive !== 'undefined'
      )
        params.where = {};
      if (typeof params.subject_id !== 'undefined') params.where.subject_id = params.subject_id;
      delete params.subject_id;
      if (typeof params.scope_type !== 'undefined') params.where.scope_type = params.scope_type;
      delete params.scope_type;
      if (typeof params.manager !== 'undefined') params.where.manager_id = params.manager;
      delete params.manager;
      if (typeof params.current !== 'undefined') params.where.current = params.current;
      delete params.current;
      if (typeof params.archive !== 'undefined') params.where.archive = params.archive;
      delete params.archive;

      const newPayments = await Payments.initOverall({ params });

      await commit('SET_PAYMENTS', newPayments.data.data);

      return true;
    } catch (e) {
      await commit('SET_PAYMENTS', []);
    }
  },
  async create({ commit }, data) {
    try {
      const newPayment = await Payments.create(data);

      await commit('SET_MESSAGE', newPayment);

      return newPayment;
    } catch (e) {
      return e.response;
    }
  },
  async update({ commit }, payload) {
    try {
      const updatedPayment = await Payments.update(payload);

      await commit('SET_MESSAGE', updatedPayment);

      return updatedPayment;
    } catch (e) {
      return e.response;
    }
  },
  async delete({ commit }, id) {
    try {
      const data = await Payments.delete(id);

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_PAYMENTS(state, payload) {
    state.payments = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
