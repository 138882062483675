import projectsSingle from '@/router/routes/projects-single/pages';

export default [
  {
    name: 'projects-single',
    path: '/projects-new/:id',
    redirect: {
      name: 'projects-single.contracts',
    },
    meta: {
      middleware: ['checkIfDesignerRoleOnly'],
    },
    component: () => import('@/views/projects-single/PxvIndex'),
    children: [...projectsSingle],
  },
  {
    name: 'projects-single-archive',
    path: '/projects-new/:id/archive',
    meta: {
      middleware: ['checkIfDesignerRoleOnly'],
    },
    component: () => import('@/views/projects-single/PxvIndex'),
  },
];
