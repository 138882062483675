import Users from '@/api/Users';

const state = {
  users: [],
  managers: [],
  designers: [],
  message: null,
};

const getters = {
  users(state) {
    return state.users;
  },
  managers(state) {
    return state.managers;
  },
  designers(state) {
    return state.designers;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.fields) params.fields = params.fields || '';
      if (params.groups) params.where = { groups: params.groups };
      delete params.groups;

      const data = await Users.index({ params });

      await commit('SET_USERS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async managersIndex({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.fields) params.fields = params.fields || '';
      params.where = { groups: ['Manager'] };
      delete params.groups;

      const data = await Users.index({ params });

      await commit('SET_MANAGERS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async designersIndex({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      if (params.fields) params.fields = params.fields || '';
      params.where = { groups: ['Designer'] };
      delete params.groups;

      const data = await Users.index({ params });

      await commit('SET_DESIGNERS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async create({ commit }, payload) {
    try {
      const data = await Users.create(payload);
      await commit('SET_MESSAGE', data);
      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async single(_, id) {
    try {
      return await Users.single(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const data = await Users.update({
        id: payload.id,
        ...payload,
      });
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_MANAGERS(state, payload) {
    state.managers = payload;
  },
  SET_DESIGNERS(state, payload) {
    state.designers = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
