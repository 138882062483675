import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default new VueI18n({
  numberFormats: {
    'en-CA': {
      currency: {
        style: 'currency',
        currency: 'CAD',
      },
      currencyNonFraction: {
        style: 'currency',
        currency: 'CAD',
        maximumFractionDigits: 0,
      },
      quantity: {
        maximumFractionDigits: 0,
      },
    },
  },
});
