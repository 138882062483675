import AttachedSpecifications from '@/api/AttachedSpecifications';

const state = {
  attachedSpecifications: [],
  message: null,
};

const getters = {
  attachedSpecifications(state) {
    return state.attachedSpecifications;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';

      if (params.where) params.where = params.where || {};

      const data = await AttachedSpecifications.index({ params });

      await commit('SET_ATTACHED_SPECIFICATIONS', data.data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_ATTACHED_SPECIFICATIONS(state, payload) {
    state.attachedSpecifications = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
