export default [
  {
    path: '/map',
    component: () => import('@/views/map/PxvMap'),
    meta: {
      title: 'Map',
      middleware: ['checkIfDesignerRoleOnly'],
    },
    name: 'map',
  },
];
