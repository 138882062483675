export default [
  {
    path: '/planning',
    component: () => import('@/views/planning/PxvPlanning'),
    meta: {
      title: 'Planning',
      middleware: ['checkIfDesignerRoleOnly'],
    },
    name: 'planning',
  },
];
