import { X_RAPIDAPI_KEY, X_RAPIDAPI_HOST, OPEN_WEATHER_MAP_KEY } from '@/shared/constants';

import axios from 'axios';

const WeatherApiService = {
  async getIp() {
    let newIp = null;

    await fetch('https://api.ipify.org?format=json')
      .then((data) => data.json())
      .then(({ ip }) => (newIp = ip));

    return newIp;
  },
  async getLocation(ip) {
    let newLocation = null;

    await fetch(`https://ipapi.co/${ip}/json`)
      .then((data) => data.json())
      .then((data) => (newLocation = data));

    return newLocation;
  },
  async getWeather(lat, lon) {
    let newWeather = null;

    await fetch(
      `https://api.openweathermap.org/data/2.5/forecast/daily?lat=${lat}&lon=${lon}&cnt=6&appid=${OPEN_WEATHER_MAP_KEY}`
    )
      .then((data) => data.json())
      .then((data) => (newWeather = data));

    return newWeather;
  },
  get(location) {
    return axios.request({
      method: 'GET',
      url: 'https://visual-crossing-weather.p.rapidapi.com/forecast',
      params: {
        aggregateHours: '24',
        location,
        contentType: 'json',
        unitGroup: 'metric',
        shortColumnNames: '0',
        iconSet: 'icons1',
      },
      headers: {
        'X-RapidAPI-Key': X_RAPIDAPI_KEY,
        'X-RapidAPI-Host': X_RAPIDAPI_HOST,
      },
    });
  },
};

export default WeatherApiService;
