import ApiService from '@/utils/api.service';

const FiberglassPoolTemplate = {
  index(data) {
    return ApiService.get(`fiberglass_pool_templates`, data);
  },
  create(data) {
    return ApiService.post(`fiberglass_pool_templates`, data);
  },
  single(id) {
    return ApiService.get(`fiberglass_pool_templates/${id}`);
  },
  update(data) {
    return ApiService.put(`fiberglass_pool_templates/${data.id}`, data.attributes);
  },
  delete(id) {
    return ApiService.delete(`fiberglass_pool_templates/${id}`);
  },
};

export default FiberglassPoolTemplate;
