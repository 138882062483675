export default [
  {
    path: 'contracts',
    component: () => import('@/views/projects/views/PxvSingleProjectContracts'),
    meta: { title: 'Single Project Contracts' },
    name: 'single-project.contracts',
  },
  {
    path: 'statements',
    component: () => import('@/views/projects/views/PxvSingleProjectStatements'),
    meta: { title: 'Single Project Statements' },
    name: 'single-project.statements',
  },
  {
    path: 'internal-notes',
    component: () => import('@/views/projects/views/PxvSingleProjectInternalNotes'),
    meta: { title: 'Single Project Internal Notes' },
    name: 'single-project.internal-notes',
  },
  {
    path: 'activity',
    component: () => import('@/views/projects/views/PxvSingleProjectActivity'),
    meta: { title: 'Single Project Activity' },
    name: 'single-project.activity',
  },
];
