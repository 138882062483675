<template>
  <div class="position-relative row align-items-center flex-nowrap bg-gray-100 rounded gx-0">
    <button
      class="btn btn-icon btn-active-color-gray-700 position-absolute w-auto h-auto translate-middle-y top-50 start-0 p-0 ms-2"
      @click="increase()"
      type="button"
    >
      <span class="svg-icon svg-icon-1">
        <inline-svg src="/assets/media/icons/duotune/general/gen036.svg" />
      </span>
    </button>
    <div class="col">
      <input
        ref="input"
        v-bind="$attrs"
        type="text"
        :class="[
          'form-control bg-transparent border-0',
          { 'ps-12 pe-0': hint },
          { 'px-12': !hint },
          validateModel(state),
        ]"
        @input="updateValue($event.target.value)"
      />
    </div>
    <div v-if="hint" class="col-auto">
      <span class="bg-transparent ps-4 pe-12">{{ hint }}</span>
    </div>
    <button
      class="btn btn-icon btn-active-color-gray-700 position-absolute w-auto h-auto translate-middle-y top-50 end-0 p-0 me-2"
      type="button"
      @click="decrease"
    >
      <span class="svg-icon svg-icon-1">
        <inline-svg src="/assets/media/icons/duotune/general/gen035.svg" />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);
    },
    increase: function () {
      this.$emit('input', parseInt(this.$refs.input.value) <= 0 ? 0 : parseInt(this.$refs.input.value) - 1);
    },
    decrease: function () {
      this.$emit('input', parseInt(this.$refs.input.value) + 1);
    },
  },
};
</script>
