<template>
  <div class="ql-quil ql-quil-plain bg-gray-100 p-4 rounded" v-bind="$attrs">
    <!--begin::Editor-->
    <div ref="editor" class="min-h-300px" v-html="editorContent" />
    <!--end::Editor-->

    <!--begin::Toolbar-->
    <div ref="editorsTools" class="ql-toolbar d-flex flex-stack border-top border-gray-300 rounded-0">
      <div class="me-2">
        <span class="ql-formats">
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-list" value="ordered" />
          <button class="ql-list" value="bullet" />
          <button class="ql-clean" />
        </span>
      </div>
      <div class="me-n3">
        <span class="btn btn-icon btn-sm btn-active-color-primary">
          <i class="flaticon2-clip-symbol icon-ms" />
        </span>
        <span class="btn btn-icon btn-sm btn-active-color-primary">
          <i class="flaticon2-pin icon-ms" />
        </span>
      </div>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import Quill from 'quill';

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      editorContent: this.content,
    };
  },
  mounted() {
    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: {
          container: this.$refs.editorsTools,
        },
      },
      placeholder: 'Type...',
      theme: 'snow',
    });
    this.editor.on('text-change', () => {
      this.$emit('on-text-change', true);
    });
  },
  methods: {
    getContent: function () {
      return this.editor.root.innerHTML;
    },
  },
};
</script>

<style lang="scss">
.ql-container {
  p {
    margin-bottom: 0.5rem !important;
  }
}
</style>
