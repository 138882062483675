<template>
  <div
    v-bind="$attrs"
    id="kt_header"
    class="d-print-none header align-items-stretch"
    data-kt-sticky="true"
    data-kt-sticky-name="header"
    data-kt-sticky-offset="{default: '200px', lg: '300px'}"
  >
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => KTSticky.createInstances());
  },
};
</script>
