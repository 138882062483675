import Adjustments from '@/api/Adjustments';

const state = {
  adjustments: [],
  message: null,
};

const getters = {
  adjustments(state) {
    return state.adjustments;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async initOverall({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      if (typeof params.proposal_scope_id !== 'undefined' || typeof params.scope_type !== 'undefined')
        params.where = {};
      if (typeof params.proposal_scope_id !== 'undefined') params.where.proposal_scope_id = params.proposal_scope_id;
      delete params.proposal_scope_id;
      if (typeof params.scope_type !== 'undefined') params.where.scope_type = params.scope_type;
      delete params.scope_type;

      const data = await Adjustments.initOverall({ params });

      await commit('SET_ADJUSTMENTS', data.data.data);

      return true;
    } catch (e) {
      await commit('SET_ADJUSTMENTS', null);
    }
  },
  async create({ commit }, data) {
    try {
      const newAdjustment = await Adjustments.create(data);

      await commit('SET_MESSAGE', newAdjustment);

      return newAdjustment;
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const updatedAdjustment = await Adjustments.update(payload);

      await commit('SET_MESSAGE', updatedAdjustment);

      return updatedAdjustment;
    } catch (e) {
      throw new Error(e);
    }
  },
  async delete({ commit }, id) {
    try {
      const data = await Adjustments.delete(id);

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_ADJUSTMENTS(state, payload) {
    state.adjustments = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
