import ApiService from '@/utils/api.service';

const Users = {
  index(data) {
    return ApiService.get(`users`, data);
  },
  create(data) {
    return ApiService.post(`users`, data);
  },
  single(id) {
    return ApiService.get(`users/${id}`);
  },
  update(data) {
    return ApiService.put(`users/${data.id}`, data);
  },
  delete(id) {
    return ApiService.delete(`users/${id}`);
  },
};

export default Users;
