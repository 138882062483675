<template>
  <div v-bind="$attrs" class="w-100">
    <select
      v-select2
      :class="[classes, validateModel(state)]"
      :value="value"
      :id="select2Id"
      :multiple="multiple"
      data-control="select2"
      :data-minimum-results-for-search="minimumResultsForSearch"
      :data-allow-clear="clear && !disabled"
      :data-placeholder="placeholder"
      :data-dropdown-parent="parent"
      :disabled="disabled"
      @change="onChange"
    >
      <option v-if="!multiple" />
      <option
        v-for="(item, index) in items"
        :value="getBy(item)"
        :key="`${select2Id}_${getBy(item)}_option_${index}`"
        :data-kt-rich-content-subcontent="getSubText(item)"
      >
        {{ getItem(item) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    classes: { type: String, default: 'form-select form-select-solid fw-normal' },
    items: { type: Array, default: () => [] },
    lockedItems: { type: Array, default: () => [] },
    value: { type: [Array, String, Number], default: () => [] },
    trackBy: { type: String },
    minimumResultsForSearch: { type: Number, default: 1 },
    textBy: { type: [Array, String] },
    subText: { type: String },
    subTextIsDate: { type: Boolean },
    select2Id: { type: String },
    state: { type: Boolean },
    clear: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    parent: { type: String },
    disabled: { type: Boolean },
  },
  mounted() {
    this.$nextTick(() => {
      this.reRenderSelect();
    });
  },
  updated() {
    this.reRenderSelect();
  },
  watch: {
    items: {
      handler() {
        this.reRenderSelect();
      },
    },
  },
  methods: {
    getSubText(item) {
      if (!this.subText) return false;

      let arr = this.subText ? this.subText.split('.') : null;
      const formattedStr = arr[1] ? item[arr[0]][arr[1]] : item[arr[0]];

      return this.subTextIsDate ? this.$moment(formattedStr).format('MMMM, D YYYY') : formattedStr;
    },
    scopeNameByParam(param) {
      switch (param) {
        case 'Swimming Pool':
          return 'Watershape';
        case 'Landscaping':
          return 'Landscaping';
        case 'Woodworking':
          return 'Woodworking';
        default:
          return null;
      }
    },
    getItem(item) {
      if (typeof this.textBy === 'object') {
        let title = [];

        for (let prop of this.textBy) {
          if (prop.includes('.')) {
            let arr = prop.split('.');

            title.push(arr[1] === 'scope_type' ? this.scopeNameByParam(item[arr[0]][arr[1]]) : item[arr[0]][arr[1]]);
          } else {
            title.push(prop);
          }
        }

        return title.join('');
      } else {
        let arr = this.textBy ? this.textBy.split('.') : null;
        let chain = item;

        for (let i = 0; i < arr.length; i++) {
          chain = chain[arr[i]];
        }

        return arr.length > 1 ? chain : item[arr[0]];
      }
    },
    getBy(item) {
      let arr = this.trackBy ? this.trackBy.split('.') : null;
      let chain = item;

      for (let i = 0; i < arr.length; i++) {
        chain = chain[arr[i]];
      }

      return arr.length > 1 ? chain : item[arr[0]];
    },
    onChange() {
      this.$emit('input', $(`#${this.select2Id}`).select2('val'));
    },
    reRenderSelect() {
      const optionFormat = (item) => {
        if (!item.id) {
          return item.text;
        }

        const span = document.createElement('span');
        span.innerHTML = `<div class="text-dark">
          ${item.text}
          <span class="d-block text-muted small">${item.element.getAttribute('data-kt-rich-content-subcontent')}</span>
        </div>`;

        return $(span);
      };
      $(`#${this.select2Id}`)
        .select2(
          this.subText
            ? {
                templateSelection: optionFormat,
                templateResult: optionFormat,
              }
            : {}
        )
        .on('select2:unselecting', (evt) => {
          if (this.lockedItems && this.lockedItems.includes(evt.params.args.data.id)) {
            evt.preventDefault();
          }
        });
      $(`#${this.select2Id}`).val(this.value).trigger('change');
    },
  },
};
</script>

<style lang="scss">
.select2-container {
  &.select2-container--bootstrap5 {
    .select2-dropdown {
      .select2-results__option {
        padding: 0.75rem 1.5rem 0.75rem 0.75rem;

        &.select2-results__option--selected {
          background-position: center right 0.4rem;
        }
      }
    }
  }
}
</style>
