import PlantingItemTemplate from '@/api/PlantingItemTemplate';

const state = {
  items: [],
  categories: null,
  message: null,
};

const getters = {
  items(state) {
    return state.items;
  },
  categories(state) {
    return state.categories;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async index({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.where = { archive: false };
      if (params.subject_id) params.where = { subject_id: params.subject_id };
      delete params.subject_id;

      const data = await PlantingItemTemplate.index({ params });

      await commit('SET_ITEMS', data.data.data);

      return true;
    } catch (e) {
      await commit('SET_ITEMS', []);
    }
  },
  async initCategories({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10000;
      params.page = params.page || 1;
      params.order = params.order || 'desc';

      const data = await PlantingItemTemplate.index({ params });
      const categories = new Map();

      data.data.data.forEach((el) => {
        categories.set(el.attributes.category, new Set());
      });

      data.data.data.forEach((el) => {
        categories.set(el.attributes.category, categories.get(el.attributes.category).add(el.attributes.subcategory));
      });

      await commit('SET_CATEGORIES', categories);

      return true;
    } catch (e) {
      await commit('SET_CATEGORIES', {});
    }
  },
  async create({ commit }, payload) {
    try {
      const data = await PlantingItemTemplate.create(payload);

      await commit('SET_MESSAGE', data);

      return {
        ...data,
        data: {
          ...data.data,
          data: {
            ...data.data.data,
            attributes: {
              ...data.data.data.attributes,
              price: data.data.data.attributes.price,
            },
          },
        },
      };
    } catch (e) {
      throw new Error(e);
    }
  },
  async show(_, id) {
    try {
      return await PlantingItemTemplate.show(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async single(_, id) {
    try {
      return await PlantingItemTemplate.single(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const data = await PlantingItemTemplate.update(payload);

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async delete({ commit }, id) {
    try {
      const data = await PlantingItemTemplate.delete(id);
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async archive({ commit }, id) {
    try {
      const data = await PlantingItemTemplate.update({ id, attributes: { archive: true } });

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
