import singleProject from '@/router/routes/projects/single-project';

export default [
  {
    path: '/projects',
    component: () => import('@/views/projects/PxvProjects'),
    meta: {
      title: 'Projects',
      middleware: ['checkIfDesignerRoleOnly'],
    },
    name: 'projects',
  },
  {
    path: '/projects/archive',
    component: () => import('@/views/projects/PxvProjectsArchive'),
    meta: {
      title: 'Archive',
      middleware: ['checkIfDesignerRoleOnly'],
    },
    name: 'projects-archive',
  },
  {
    path: '/projects/:id',
    redirect: {
      name: 'single-project.contracts',
    },
    component: () => import('@/views/projects/PxvSingleProject'),
    meta: {
      title: 'Single Project',
      middleware: ['checkIfDesignerRoleOnly'],
    },
    name: 'single-project',
    children: [...singleProject],
  },
  {
    path: '/projects/:id/archive',
    component: () => import('@/views/projects/PxvSingleProjectArchive'),
    meta: {
      title: 'Single Project Archive',
      middleware: ['checkIfDesignerRoleOnly'],
    },
    name: 'single-project-archive',
  },
];
