import dashboard from '@/router/routes/dashboard';
import map from '@/router/routes/map';
import planning from '@/router/routes/planning';
import projects from '@/router/routes/projects';
import projectsSingle from '@/router/routes/projects-single';
import proposals from '@/router/routes/proposals';

import proposalWizard from '@/router/routes/proposals/proposal-wizard';

import login from '@/router/routes/auth/login';
import register from '@/router/routes/auth/register';
import error from '@/router/routes/error';

export default [
  {
    path: '/admin',
    component: () => import('@/layouts/PxMainLayout'),
    meta: {
      middleware: ['authenticated'],
    },
    children: [...dashboard, ...map, ...planning, ...proposals, ...projects, ...projectsSingle],
  },
  {
    name: 'proposal-wizard',
    path: '/proposals/wizard/:id',
    redirect: {
      name: 'proposal-wizard.introduction',
    },
    meta: {
      middleware: ['authenticated'],
    },
    component: () => import('@/layouts/PxProposalWizardLayout.vue'),
    children: [...proposalWizard],
  },
  {
    name: 'proposal-preview',
    path: '/proposals/preview/:id',
    meta: {
      middleware: ['authenticated'],
    },
    component: () => import('@/views/proposals/PxvProposalPreview.vue'),
  },
  {
    name: 'project-preview',
    path: '/projects/preview/:id',
    meta: {
      middleware: ['hasCode', 'checkIfDesignerRoleOnly'],
    },
    component: () => import('@/views/projects/PxvProjectPreview.vue'),
  },
  {
    path: '/',
    component: () => import('@/layouts/PxAuthLayout'),
    meta: {
      middleware: ['unAuthenticated'],
    },
    children: [...login, ...register],
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/404',
    component: () => import('@/layouts/PxMainLayout'),
    children: [...error],
  },
];
