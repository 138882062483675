import LeadTasks from '@/api/LeadTasks';

const state = {
  lead_tasks: [],
  message: null,
};

const getters = {
  leadTasks(state) {
    return state.lead_tasks;
  },
  message(state) {
    return state.message;
  },
};

const actions = {
  async initOverall({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = 10000;
      params.page = 1;
      if (params.fields) params.fields = params.fields || '';

      const data = await LeadTasks.initOverall({ params });

      await commit('SET_LEAD_TASKS', data.data);

      return true;
    } catch (e) {
      await commit('SET_LEAD_TASKS', []);
    }
  },
  async create({ commit }, payload) {
    try {
      const newLeadTask = await LeadTasks.create(payload);

      await commit('SET_MESSAGE', newLeadTask);

      return newLeadTask.data.data;
    } catch (e) {
      throw new Error(e);
    }
  },
  async single(_, id) {
    try {
      return await LeadTasks.single(id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async update({ commit }, payload) {
    try {
      const updatedLeadTask = await LeadTasks.update(payload);

      await commit('SET_MESSAGE', updatedLeadTask);

      return updatedLeadTask;
    } catch (e) {
      throw new Error(e);
    }
  },
  async delete({ commit }, id) {
    try {
      const data = await LeadTasks.delete(id);

      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_LEAD_TASKS(state, payload) {
    state.lead_tasks = payload;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
