import Project from '@/api/Project';

const state = {
  lastProject: null,
};

const getters = {
  lastProject(state) {
    return state.lastProject;
  },
};

const actions = {
  async lastProject({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = 1;
      params.page = 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {};
      params.where.archive = false;
      params.where.hidden = false;
      if (typeof params.current !== 'undefined') params.where.current = params.current;
      delete params.current;
      if (typeof params.manager_id !== 'undefined') params.where.manager_id = params.manager_id;
      delete params.manager_id;

      const data = await Project.names({ params });

      await commit('SET_LAST_PROJECT', data.data.data[0]);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
};

const mutations = {
  SET_LAST_PROJECT(state, payload) {
    state.lastProject = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
