import Proposal from '@/api/Proposal';

const state = {
  paymentScheduleItems: [],
  subTotal: 0,
  pricingAndPaymentInformation: null,
  termsConditionsAndWarranties: null,
};

const getters = {
  paymentScheduleItems(state) {
    return state.paymentScheduleItems;
  },
  subTotal(state) {
    return state.subTotal;
  },
  pricingAndPaymentInformation(state) {
    return state.pricingAndPaymentInformation;
  },
  termsConditionsAndWarranties(state) {
    return state.termsConditionsAndWarranties;
  },
};

const actions = {
  async initOverall({ commit }, { id, params = {} }) {
    const proposal = await Proposal.single(id, { params });
    const proposalAttributes = proposal?.data?.data?.attributes;

    await commit('SET_PAYMENT_SCHEDULE_ITEMS', proposalAttributes?.payment_schedule_items?.data || []);
    await commit('SET_SUB_TOTAL', proposalAttributes?.sub_total || 0);
    await commit(
      'SET_PRICING_AND_PAYMENT_INFORMATION',
      proposalAttributes?.pricing_and_payment_information
        ? JSON.parse(proposalAttributes?.pricing_and_payment_information)
        : ''
    );
    await commit(
      'SET_TERMS_CONDITIONS_AND_WARRANTIES',
      proposalAttributes?.terms_conditions_warranties ? JSON.parse(proposalAttributes?.terms_conditions_warranties) : ''
    );
  },
};

const mutations = {
  SET_PAYMENT_SCHEDULE_ITEMS(state, payload) {
    state.paymentScheduleItems = payload;
  },
  SET_SUB_TOTAL(state, payload) {
    state.subTotal = payload;
  },
  SET_PRICING_AND_PAYMENT_INFORMATION(state, payload) {
    state.pricingAndPaymentInformation = payload;
  },
  SET_TERMS_CONDITIONS_AND_WARRANTIES(state, payload) {
    state.termsConditionsAndWarranties = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
