import { cloneDeep } from 'lodash';

import Proposal from '@/api/Proposal';
import PricingItemTemplate from '@/api/PricingItemTemplate';
import PlantingItemTemplate from '@/api/PlantingItemTemplate';

const state = {
  currentProposal: null,
  topMenu: null,
  isLoaded: false,
  currentStep: 1,
  currentRoute: null,

  message: null,

  isRenovate: false,
  state: null,
  scope_type: null,
  construction: null,

  fiberglass_pool_template_id: null,
  fiberglass_details: '',
  is_fiberglass_custom: null,

  shape: null,
  shape_name: null,
  custom_shape_text: null,
  size_category: null,
  width: null,
  length: null,
  shallow: null,
  deepend: null,
  introduction: null,
  construction_details: null,
  special_notes: null,
  pricing_and_payment_information: null,
  terms_conditions_warranties: null,
  version: null,
  date: null,
  value: null,
  status: null,
  inclusions: {
    data: [],
  },
  pricing_sections: {
    data: [],
  },
  pricing_section_active_index: 0,
  equipment_items: {
    data: [],
  },
  payment_schedule_items: {
    data: [],
  },
  sub_total: null,
  sub_total_percentage: null,
  empty_price: null,
  created_at: null,
  updated_at: null,
  subject: {
    data: null,
  },
  users: {
    data: [],
  },
  user_ids: [],
  planting_sections: {
    data: [],
  },
  planting_section_active_index: 0,
  pdf_file: null,
  pdf_ready: false,
  signed_pdf_file: false,
  shared: null,
  sent_to_sign: null,

  pricing_template_categories: [],
  planting_template_categories: [],

  show_pricing_item: false,
  show_pricing_description: false,
  show_pricing_quantity: false,
  show_pricing_price: false,
  show_pricing_unit: false,
  show_pricing_waste: false,
  show_pricing_total: false,

  show_planting_category: false,
  show_planting_description: false,
  show_planting_item: false,
  show_planting_multiplier: false,
  show_planting_notes: false,
  show_planting_price: false,
  show_planting_quantity: false,
  show_planting_size: false,
  show_planting_total: false,
};

const getters = {
  currentProposal(state) {
    return state.currentProposal;
  },
  topMenu(state) {
    return state.topMenu;
  },
  isLoaded(state) {
    return state.isLoaded;
  },
  currentStep(state) {
    return state.currentStep;
  },
  currentRoute(state) {
    return state.currentRoute;
  },

  message(state) {
    return state.message;
  },

  isRenovate(state) {
    return state.isRenovate;
  },
  state(state) {
    return state.state;
  },
  scopeType(state) {
    return state.scope_type;
  },
  construction(state) {
    return state.construction;
  },

  fiberglassPoolTemplateId(state) {
    return state.fiberglass_pool_template_id;
  },
  fiberglassDetails(state) {
    return state.fiberglass_details;
  },
  isFiberglassCustom(state) {
    return state.is_fiberglass_custom;
  },

  shape(state) {
    return state.shape;
  },
  shapeName(state) {
    return state.shape_name;
  },
  customShapeText(state) {
    return state.custom_shape_text;
  },
  sizeCategory(state) {
    return state.size_category;
  },
  width(state) {
    return state.width;
  },
  length(state) {
    return state.length;
  },
  shallow(state) {
    return state.shallow;
  },
  deepend(state) {
    return state.deepend;
  },
  introduction(state) {
    return state.introduction;
  },
  constructionDetails(state) {
    return state.construction_details;
  },
  specialNotes(state) {
    return state.special_notes;
  },
  pricingAndPaymentInformation(state) {
    return state.pricing_and_payment_information;
  },
  termsConditionsWarranties(state) {
    return state.terms_conditions_warranties;
  },
  version(state) {
    return state.version;
  },
  date(state) {
    return state.date;
  },
  value(state) {
    return state.value;
  },
  status(state) {
    return state.status;
  },
  inclusions(state) {
    return state.inclusions.data;
  },
  pricingSections(state) {
    return state.pricing_sections.data;
  },
  pricingSectionActiveIndex(state) {
    return state.pricing_section_active_index;
  },
  equipmentItems(state) {
    return state.equipment_items.data;
  },
  paymentScheduleItems(state) {
    return state.payment_schedule_items.data;
  },
  subTotal(state) {
    return state.sub_total;
  },
  subTotalPercentage(state) {
    return state.sub_total_percentage;
  },
  emptyPrice(state) {
    return state.empty_price;
  },
  createdAt(state) {
    return state.created_at;
  },
  updatedAt(state) {
    return state.updated_at;
  },
  subject(state) {
    return state.subject.data;
  },
  users(state) {
    return state.users.data;
  },
  userIds(state) {
    return state.user_ids;
  },
  plantingSections(state) {
    return state.planting_sections.data;
  },
  plantingSectionActiveIndex(state) {
    return state.planting_section_active_index;
  },
  pdfFile(state) {
    return state.pdf_file;
  },
  pdfReady(state) {
    return state.pdf_ready;
  },
  signedPdfFile(state) {
    return state.signed_pdf_file;
  },
  shared(state) {
    return state.shared;
  },
  sentToSign(state) {
    return state.sent_to_sign;
  },

  pricingTemplateCategories(state) {
    return state.pricing_template_categories;
  },
  plantingTemplateCategories(state) {
    return state.planting_template_categories;
  },

  showPricingItem(state) {
    return state.show_pricing_item;
  },
  showPricingDescription(state) {
    return state.show_pricing_description;
  },
  showPricingQuantity(state) {
    return state.show_pricing_quantity;
  },
  showPricingPrice(state) {
    return state.show_pricing_price;
  },
  showPricingUnit(state) {
    return state.show_pricing_unit;
  },
  showPricingWaste(state) {
    return state.show_pricing_waste;
  },
  showPricingTotal(state) {
    return state.show_pricing_total;
  },

  showPlantingCategory(state) {
    return state.show_planting_category;
  },
  showPlantingDescription(state) {
    return state.show_planting_description;
  },
  showPlantingItem(state) {
    return state.show_planting_item;
  },
  showPlantingMultiplier(state) {
    return state.show_planting_multiplier;
  },
  showPlantingNotes(state) {
    return state.show_planting_notes;
  },
  showPlantingPrice(state) {
    return state.show_planting_price;
  },
  showPlantingQuantity(state) {
    return state.show_planting_quantity;
  },
  showPlantingSize(state) {
    return state.show_planting_size;
  },
  showPlantingTotal(state) {
    return state.show_planting_total;
  },
};

const actions = {
  async initOverall({ commit, getters }, { id, params = {} }) {
    try {
      if (params.fields) params.fields = params.fields || '';

      const proposal = await Proposal.single(id, { params });
      const proposalAttributes = await proposal?.data?.data?.attributes;
      let topMenu = [];

      if (proposalAttributes?.state === 'Change Order') {
        switch (proposalAttributes?.scope_type) {
          case 'Swimming Pool':
            topMenu.push(
              { title: 'Pricing', pathName: 'proposal-wizard.pricing' },
              { title: 'Notes', pathName: 'proposal-wizard.notes' },
              { title: 'Payment Details', pathName: 'proposal-wizard.payment-details' },
              { title: 'Preview', pathName: 'proposal-wizard.preview' }
            );
            break;
          case 'Woodworking':
            topMenu.push(
              { title: 'Pricing', pathName: 'proposal-wizard.pricing' },
              { title: 'Notes', pathName: 'proposal-wizard.notes' },
              { title: 'Payment Details', pathName: 'proposal-wizard.payment-details' },
              { title: 'Preview', pathName: 'proposal-wizard.preview' }
            );
            break;
          case 'Landscaping':
            topMenu.push(
              { title: 'Pricing', pathName: 'proposal-wizard.pricing' },
              { title: 'Planting', pathName: 'proposal-wizard.planting' },
              { title: 'Notes', pathName: 'proposal-wizard.notes' },
              { title: 'Payment Details', pathName: 'proposal-wizard.payment-details' },
              { title: 'Preview', pathName: 'proposal-wizard.preview' }
            );
            break;
          default:
            break;
        }
      } else {
        switch (proposalAttributes?.scope_type) {
          case 'Swimming Pool':
            topMenu.push(
              { title: 'Introduction', pathName: 'proposal-wizard.introduction' },
              { title: 'Specifications', pathName: 'proposal-wizard.specifications' }
            );
            if (proposalAttributes?.construction !== 'Renovate') {
              topMenu.push({ title: 'Equipment', pathName: 'proposal-wizard.equipment' });
            }
            topMenu.push(
              { title: 'Pricing', pathName: 'proposal-wizard.pricing' },
              { title: 'Notes', pathName: 'proposal-wizard.notes' },
              { title: 'Considerations', pathName: 'proposal-wizard.considerations' },
              { title: 'Payment Details', pathName: 'proposal-wizard.payment-details' },
              { title: 'Preview', pathName: 'proposal-wizard.preview' }
            );
            break;
          case 'Woodworking':
            topMenu.push(
              { title: 'Introduction', pathName: 'proposal-wizard.introduction' },
              { title: 'Pricing', pathName: 'proposal-wizard.pricing' },
              { title: 'Notes', pathName: 'proposal-wizard.notes' },
              { title: 'Considerations', pathName: 'proposal-wizard.considerations' },
              { title: 'Payment Details', pathName: 'proposal-wizard.payment-details' },
              { title: 'Preview', pathName: 'proposal-wizard.preview' }
            );
            break;
          case 'Landscaping':
            topMenu.push(
              { title: 'Introduction', pathName: 'proposal-wizard.introduction' },
              { title: 'Pricing', pathName: 'proposal-wizard.pricing' },
              { title: 'Planting', pathName: 'proposal-wizard.planting' },
              { title: 'Notes', pathName: 'proposal-wizard.notes' },
              { title: 'Considerations', pathName: 'proposal-wizard.considerations' },
              { title: 'Payment Details', pathName: 'proposal-wizard.payment-details' },
              { title: 'Preview', pathName: 'proposal-wizard.preview' }
            );
            break;
          default:
            break;
        }
      }

      await commit('SET_IS_LOADED', false);
      await commit('SET_TOP_MENU', topMenu);

      await commit('SET_IS_RENOVATE', proposalAttributes?.construction === 'Renovate');
      await commit('SET_STATE', proposalAttributes?.state || null);
      await commit('SET_SCOPE_TYPE', proposalAttributes?.scope_type || null);
      await commit('SET_CONSTRUCTION', proposalAttributes?.construction || null);

      await commit('SET_FIBERGLASS_POOL_TEMPLATE_ID', proposalAttributes?.fiberglass_pool_template_id || null);
      await commit('SET_FIBERGLASS_DETAILS', proposalAttributes?.fiberglass_details || null);
      await commit('SET_IS_FIBERGLASS_CUSTOM', proposalAttributes?.is_fiberglass_custom || false);

      await commit('SET_SHAPE', proposalAttributes?.shape || null);
      await commit('SET_SHAPE_NAME', proposalAttributes?.shape_name || null);
      await commit('SET_CUSTOM_SHAPE_TEXT', proposalAttributes?.custom_shape_text || null);
      await commit('SET_SIZE_CATEGORY', proposalAttributes?.size_category || null);
      await commit('SET_WIDTH', proposalAttributes?.width || null);
      await commit('SET_LENGTH', proposalAttributes?.length || null);
      await commit('SET_SHALLOW', proposalAttributes?.shallow || null);
      await commit('SET_DEEPEND', proposalAttributes?.deepend || null);
      await commit('SET_INTRODUCTION', proposalAttributes?.introduction || null);
      await commit('SET_CONSTRUCTION_DETAILS', proposalAttributes?.construction_details || null);
      await commit('SET_SPECIAL_NOTES', proposalAttributes?.special_notes || null);
      await commit('SET_PRICING_AND_PAYMENT_INFORMATION', proposalAttributes?.pricing_and_payment_information || null);
      await commit('SET_TERMS_CONDITIONS_WARRANTIES', proposalAttributes?.terms_conditions_warranties || null);
      await commit('SET_VERSION', proposalAttributes?.version || null);
      await commit('SET_DATE', proposalAttributes?.date || null);
      await commit('SET_VALUE', proposalAttributes?.value || null);
      await commit('SET_STATUS', proposalAttributes?.status || null);
      await commit('SET_INCLUSIONS', proposalAttributes?.inclusions.data || []);
      await commit('SET_PRICING_SECTIONS', proposalAttributes?.pricing_sections.data || []);
      await commit('SET_EQUIPMENT_ITEMS', proposalAttributes?.equipment_items.data || []);
      await commit('SET_PAYMENT_SCHEDULE_ITEMS', proposalAttributes?.payment_schedule_items.data || []);
      await commit('SET_SUB_TOTAL', proposalAttributes?.sub_total || null);
      await commit(
        'SET_SUB_TOTAL_PERCENTAGE',
        proposalAttributes?.sub_total ? 100 : proposalAttributes?.state === 'Change Order' ? 100 : null
      );
      await commit('SET_EMPTY_PRICE', proposalAttributes?.empty_price);
      await commit('SET_SUBJECT', proposalAttributes?.subject || null);
      await commit('SET_USERS', proposalAttributes?.users.data || []);
      await commit('SET_PDF_FILE', proposalAttributes?.pdf_file || null);
      await commit('SET_PDF_READY', proposalAttributes?.pdf_ready || null);
      await commit('SET_SIGNED_PDF_FILE', proposalAttributes?.signed_pdf_file || false);
      await commit('SET_SHARED', proposalAttributes?.shared || null);
      await commit('SET_SENT_TO_SIGN', proposalAttributes?.sent_to_sign || null);

      await commit('SET_SHOW_PRICING_ITEM', proposalAttributes?.show_pricing_item || false);
      await commit('SET_SHOW_PRICING_DESCRIPTION', proposalAttributes?.show_pricing_description || false);
      await commit('SET_SHOW_PRICING_QUANTITY', proposalAttributes?.show_pricing_quantity || false);
      await commit('SET_SHOW_PRICING_PRICE', proposalAttributes?.show_pricing_price || false);
      await commit('SET_SHOW_PRICING_UNIT', proposalAttributes?.show_pricing_unit || false);
      await commit('SET_SHOW_PRICING_WASTE', proposalAttributes?.show_pricing_waste || false);
      await commit('SET_SHOW_PRICING_TOTAL', proposalAttributes?.show_pricing_total || false);

      await commit('SET_SHOW_PLANTING_CATEGORY', proposalAttributes?.show_planting_category || false);
      await commit('SET_SHOW_PLANTING_DESCRIPTION', proposalAttributes?.show_planting_description || false);
      await commit('SET_SHOW_PLANTING_ITEM', proposalAttributes?.show_planting_item || false);
      await commit('SET_SHOW_PLANTING_MULTIPLIER', proposalAttributes?.show_planting_multiplier || false);
      await commit('SET_SHOW_PLANTING_NOTES', proposalAttributes?.show_planting_notes || false);
      await commit('SET_SHOW_PLANTING_PRICE', proposalAttributes?.show_planting_price || false);
      await commit('SET_SHOW_PLANTING_QUANTITY', proposalAttributes?.show_planting_quantity || false);
      await commit('SET_SHOW_PLANTING_SIZE', proposalAttributes?.show_planting_size || false);
      await commit('SET_SHOW_PLANTING_TOTAL', proposalAttributes?.show_planting_total || false);

      if (proposalAttributes?.users.data) {
        const userIds = [];
        proposalAttributes?.users.data.map((el) => userIds.push(el.id));

        await commit('SET_USER_IDS', userIds);
      } else {
        await commit('SET_USER_IDS', []);
      }
      await commit('SET_PLANTING_SECTIONS', proposalAttributes?.planting_sections.data || []);

      commit('SET_PRICING_SECTION_ACTIVE_INDEX', getters.pricingSections.length);
      commit('SET_PLANTING_SECTION_ACTIVE_INDEX', getters.plantingSections.length);

      getters.pricingSections.map((el) => {
        el.attributes.pricing_items.data.map((elI) => {
          this.dispatch('proposalWizard/setPricingSectionItemsPriceDividedByHundred', {
            sectionId: el.id,
            id: elI.id,
          });
        });
      });

      getters.plantingSections.map((el) => {
        el.attributes.planting_items.data.map((elI) => {
          this.dispatch('proposalWizard/setPlantingSectionItemsPriceDividedByHundred', {
            sectionId: el.id,
            id: elI.id,
          });
        });
      });

      await commit('SET_CURRENT_PROPOSAL', cloneDeep(proposal?.data?.data) || null);
      await commit('SET_IS_LOADED', true);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async initCategories({ commit }, scopeType) {
    const itemTemplatesAttributes = {
      params: {
        elasticsearch: true,
        per: 10000,
        page: 1,
        where: { archive: false },
      },
    };

    try {
      const pricingItemTemplates = await PricingItemTemplate.index(itemTemplatesAttributes);
      const pricingCategories = new Map();

      await pricingItemTemplates.data.data.forEach((el) => {
        if (el.attributes.category !== null) {
          pricingCategories.set(el.attributes.category, new Map());
        }
      });
      await pricingItemTemplates.data.data.forEach((el) => {
        if (el.attributes.category !== null) {
          pricingCategories.set(
            el.attributes.category,
            pricingCategories.get(el.attributes.category).set(el.attributes.subcategory, [])
          );
        }
      });
      await pricingItemTemplates.data.data.forEach((el) => {
        if (el.attributes.category !== null) {
          const newEl = {
            ...el,
            attributes: {
              ...el.attributes,
              price: el.attributes.price,
            },
          };

          pricingCategories.get(el.attributes.category).get(el.attributes.subcategory).push(newEl);
        }
      });

      await commit('SET_PRICING_TEMPLATE_CATEGORIES', pricingCategories);

      if (scopeType === 'Landscaping') {
        const plantingItemTemplates = await PlantingItemTemplate.index(itemTemplatesAttributes);
        const plantingCategories = new Map();

        plantingItemTemplates.data.data.forEach((el) => {
          if (el.attributes.category !== null) {
            plantingCategories.set(el.attributes.category, new Map());
          }
        });
        plantingItemTemplates.data.data.forEach((el) => {
          if (el.attributes.category !== null) {
            plantingCategories.set(
              el.attributes.category,
              plantingCategories.get(el.attributes.category).set(el.attributes.subcategory, [])
            );
          }
        });
        plantingItemTemplates.data.data.forEach((el) => {
          if (el.attributes.category !== null) {
            const newEl = {
              ...el,
              attributes: {
                ...el.attributes,
                price: el.attributes.price,
              },
            };
            plantingCategories.get(el.attributes.category).get(el.attributes.subcategory).push(newEl);
          }
        });

        await commit('SET_PLANTING_TEMPLATE_CATEGORIES', plantingCategories);
      }

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async megaUpdate({ commit, getters }, id) {
    try {
      const newData = { proposal_scope_id: id };
      if (getters.state) newData.state = getters.state;
      if (getters.scopeType) newData.scope_type = getters.scopeType;
      if (getters.construction) newData.construction = getters.construction;

      if (typeof getters.fiberglassPoolTemplateId !== 'undefined')
        newData.fiberglass_pool_template_id = getters.fiberglassPoolTemplateId;
      if (typeof getters.fiberglassDetails !== 'undefined') newData.fiberglass_details = getters.fiberglassDetails;
      if (typeof getters.isFiberglassCustom !== 'undefined') newData.is_fiberglass_custom = getters.isFiberglassCustom;

      if (getters.shape) newData.shape = getters.shape;
      if (getters.shapeName) newData.shape_name = getters.shapeName;
      if (getters.customShapeText) newData.custom_shape_text = getters.customShapeText;
      if (getters.sizeCategory) newData.size_category = getters.sizeCategory;
      if (getters.width) newData.width = getters.width;
      if (getters.length) newData.length = getters.length;
      if (getters.shallow) newData.shallow = getters.shallow;
      if (getters.deepend) newData.deepend = getters.deepend;
      if (getters.introduction) newData.introduction = getters.introduction;
      newData.construction_details = getters.constructionDetails;
      if (getters.specialNotes) newData.special_notes = getters.specialNotes;
      if (getters.pricingAndPaymentInformation) {
        newData.pricing_and_payment_information = getters.pricingAndPaymentInformation;
      }
      if (getters.termsConditionsWarranties) newData.terms_conditions_warranties = getters.termsConditionsWarranties;
      if (getters.version) newData.version = getters.version;
      if (getters.date) newData.date = getters.date;
      if (getters.value) newData.value = getters.value;
      if (getters.status) newData.status = getters.status;
      newData.inclusions = { data: getters.inclusions };
      newData.pricing_sections = { data: getters.pricingSections };
      newData.equipment_items = { data: getters.equipmentItems };
      newData.payment_schedule_items = { data: getters.paymentScheduleItems };

      if (getters.subTotal) newData.sub_total = getters.subTotal;
      if (typeof getters.emptyPrice !== 'undefined') newData.empty_price = getters.emptyPrice;
      if (getters.subject) newData.subject = { data: getters.subject };
      if (getters.userIds) newData.user_ids = getters.userIds;
      newData.planting_sections = { data: getters.plantingSections };
      if (getters.pdfFile) newData.pdf_file = getters.pdfFile;
      if (getters.pdfReady) newData.pdf_ready = getters.pdfReady;
      if (typeof getters.signedPdfFile !== 'undefined') newData.signed_pdf_file = getters.signedPdfFile;
      if (getters.shared) newData.shared = getters.shared;
      if (getters.sentToSign) newData.sent_to_sign = getters.sentToSign;

      if (typeof getters.showPricingItem !== 'undefined') newData.show_pricing_item = getters.showPricingItem;
      if (typeof getters.showPricingDescription !== 'undefined')
        newData.show_pricing_description = getters.showPricingDescription;
      if (typeof getters.showPricingQuantity !== 'undefined')
        newData.show_pricing_quantity = getters.showPricingQuantity;
      if (typeof getters.showPricingPrice !== 'undefined') newData.show_pricing_price = getters.showPricingPrice;
      if (typeof getters.showPricingUnit !== 'undefined') newData.show_pricing_unit = getters.showPricingUnit;
      if (typeof getters.showPricingWaste !== 'undefined') newData.show_pricing_waste = getters.showPricingWaste;
      if (typeof getters.showPricingTotal !== 'undefined') newData.show_pricing_total = getters.showPricingTotal;

      if (typeof getters.showPlantingCategory !== 'undefined')
        newData.show_planting_category = getters.showPlantingCategory;
      if (typeof getters.showPlantingDescription !== 'undefined')
        newData.show_planting_description = getters.showPlantingDescription;
      if (typeof getters.showPlantingItem !== 'undefined') newData.show_planting_item = getters.showPlantingItem;
      if (typeof getters.showPlantingMultiplier !== 'undefined')
        newData.show_planting_multiplier = getters.showPlantingMultiplier;
      if (typeof getters.showPlantingNotes !== 'undefined') newData.show_planting_notes = getters.showPlantingNotes;
      if (typeof getters.showPlantingPrice !== 'undefined') newData.show_planting_price = getters.showPlantingPrice;
      if (typeof getters.showPlantingQuantity !== 'undefined')
        newData.show_planting_quantity = getters.showPlantingQuantity;
      if (typeof getters.showPlantingSize !== 'undefined') newData.show_planting_size = getters.showPlantingSize;
      if (typeof getters.showPlantingTotal !== 'undefined') newData.show_planting_total = getters.showPlantingTotal;

      await commit(
        'SET_CURRENT_PROPOSAL',
        cloneDeep({
          ...getters.currentProposal,
          attributes: { ...getters.currentProposal.attributes, ...newData },
        })
      );

      // console.log('For:', newData);

      const data = await Proposal.megaUpdate(newData);

      // console.log('From:', data);

      if (
        getters.currentRoute === 'proposal-wizard.pricing' ||
        (getters.currentRoute === 'proposal-wizard.specifications' &&
          data.data.data.attributes.fiberglass_pool_template_id)
      ) {
        await commit('SET_PRICING_SECTIONS', data.data.data.attributes.pricing_sections.data);
        await commit(
          'SET_CURRENT_PROPOSAL',
          cloneDeep({
            ...getters.currentProposal,
            attributes: {
              ...getters.currentProposal.attributes,
              pricing_sections: { data: data.data.data.attributes.pricing_sections.data },
            },
          })
        );
      }

      if (getters.currentRoute === 'proposal-wizard.planting') {
        await commit('SET_PLANTING_SECTIONS', data.data.data.attributes.planting_sections.data);
        await commit(
          'SET_CURRENT_PROPOSAL',
          cloneDeep({
            ...getters.currentProposal,
            attributes: {
              ...getters.currentProposal.attributes,
              planting_sections: { data: data.data.data.attributes.planting_sections.data },
            },
          })
        );
      }

      if (
        JSON.stringify(data?.data?.data?.attributes?.equipment_items?.data) !== JSON.stringify(getters.equipmentItems)
      ) {
        await commit('SET_EQUIPMENT_ITEMS', data.data.data.attributes.equipment_items.data);
        await commit(
          'SET_CURRENT_PROPOSAL',
          cloneDeep({
            ...getters.currentProposal,
            attributes: {
              ...getters.currentProposal.attributes,
              equipment_items: { data: data.data.data.attributes.equipment_items.data },
            },
          })
        );
      }

      if (
        JSON.stringify(data?.data?.data?.attributes?.payment_schedule_items?.data) !==
        JSON.stringify(getters.paymentScheduleItems)
      ) {
        await commit('SET_PAYMENT_SCHEDULE_ITEMS', data.data.data.attributes.payment_schedule_items.data);
        await commit(
          'SET_CURRENT_PROPOSAL',
          cloneDeep({
            ...getters.currentProposal,
            attributes: {
              ...getters.currentProposal.attributes,
              payment_schedule_items: { data: data.data.data.attributes.payment_schedule_items.data },
            },
          })
        );
      }

      if (`${data?.data?.data?.attributes?.sub_total}` !== `${getters.subTotal}`) {
        await commit('SET_SUB_TOTAL', data.data.data.attributes.sub_total);
        await commit('SET_SUB_TOTAL_PERCENTAGE', 100);
      }

      if (data?.data?.data?.attributes?.empty_price !== getters.emptyPrice) {
        await commit('SET_EMPTY_PRICE', data.data.data.attributes.empty_price);
      }

      await commit('SET_UPDATED_AT', data.data.data.attributes.updated_at);
      await commit('SET_MESSAGE', data);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async share({ commit }, data) {
    try {
      const res = await Proposal.share(data);

      await commit('SET_MESSAGE', res);
      await commit('SET_SHARED', true);

      return res;
    } catch (e) {
      throw new Error(e);
    }
  },
  async makePdf({ commit }, id) {
    try {
      const res = await Proposal.makePdf(id);

      await commit('SET_MESSAGE', res);
      await commit('SET_PDF_FILE', res.data.pdf_file);
      await commit('SET_PDF_READY', true);

      return res;
    } catch (e) {
      throw new Error(e);
    }
  },
  async sendForSign({ commit }, id) {
    try {
      const res = await Proposal.sendForSign(id);

      await commit('SET_MESSAGE', res);
      await commit('SET_SENT_TO_SIGN', true);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  revertValue({ commit, getters }, field) {
    try {
      const uppercase = field.toUpperCase();
      const fieldNameToArray = field.split('_');
      const capitalizeEach = fieldNameToArray.map((el, i) =>
        i ? el.charAt(0).toUpperCase() + el.slice(1).toLowerCase() : el.toLowerCase()
      );
      const capitalizeString = capitalizeEach.join('');

      commit(`SET_${uppercase}`, !getters[capitalizeString]);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },

  async addNewPricingCategory({ commit, getters }, data) {
    const { category, subcategory } = data.attributes;
    const newCategories = cloneDeep(getters.pricingTemplateCategories);
    newCategories.get(category).get(subcategory).push(data);

    await commit('SET_PRICING_TEMPLATE_CATEGORIES', newCategories);

    return true;
  },
  async deletePricingCategory({ commit, getters }, data) {
    const { category, subcategory } = data.attributes;
    const newCategories = cloneDeep(getters.pricingTemplateCategories);
    const newItems = [...newCategories.get(category).get(subcategory)].filter((el) => el.id !== data.id);
    newCategories.get(category).set(subcategory, newItems);

    await commit('SET_PRICING_TEMPLATE_CATEGORIES', newCategories);

    return true;
  },
  async updatePricingCategory({ commit, getters }, data) {
    const { category, subcategory } = data.attributes;
    const newCategories = cloneDeep(getters.pricingTemplateCategories);
    const newItems = [...newCategories.get(category).get(subcategory)].map((el) => {
      if (el.id === data.id) {
        el.attributes = {
          ...el.attributes,
          ...data.attributes,
        };
      }

      return el;
    });
    newCategories.get(category).set(subcategory, newItems);

    await commit('SET_PRICING_TEMPLATE_CATEGORIES', newCategories);

    return true;
  },

  async addNewPlantingCategory({ commit, getters }, data) {
    const { category, subcategory } = data.attributes;
    const newCategories = cloneDeep(getters.plantingTemplateCategories);
    newCategories.get(category).get(subcategory).push(data);

    await commit('SET_PLANTING_TEMPLATE_CATEGORIES', newCategories);

    return true;
  },
  async deletePlantingCategory({ commit, getters }, data) {
    const { category, subcategory } = data.attributes;
    const newCategories = cloneDeep(getters.plantingTemplateCategories);
    const newItems = [...newCategories.get(category).get(subcategory)].filter((el) => el.id !== data.id);
    newCategories.get(category).set(subcategory, newItems);

    await commit('SET_PLANTING_TEMPLATE_CATEGORIES', newCategories);

    return true;
  },
  async updatePlantingCategory({ commit, getters }, data) {
    const { category, subcategory, old_category, old_subcategory } = data.attributes;
    const categoryChanged = old_category != category || old_subcategory != subcategory;
    const newCategories = cloneDeep(getters.plantingTemplateCategories);
    if (!categoryChanged) {
      const newItems = [...newCategories.get(category).get(subcategory)].map((el) => {
        if (el.id === data.id) {
          el.attributes = {
            ...el.attributes,
            ...data.attributes,
          };
        }

        return el;
      });
      newCategories.get(category).set(subcategory, newItems);
    } else {
      newCategories.get(category).get(subcategory).push(data);
      const newItems = [...newCategories.get(old_category).get(old_subcategory)].filter((el) => el.id !== data.id);
      newCategories.get(old_category).set(old_subcategory, newItems);
    }

    await commit('SET_PLANTING_TEMPLATE_CATEGORIES', newCategories);

    return true;
  },
  // Top Menu
  setRenovate({ commit, getters }, isRenovate) {
    if (isRenovate === true) {
      commit('SET_TOP_MENU', [...getters.topMenu.filter((el) => el.pathName !== 'proposal-wizard.equipment')]);
      commit('SET_IS_RENOVATE', true);
    } else {
      commit('SET_TOP_MENU', [
        ...getters.topMenu.slice(0, 2),
        {
          title: 'Equipment',
          pathName: 'proposal-wizard.equipment',
        },
        ...getters.topMenu.slice(2),
      ]);
      commit('SET_IS_RENOVATE', false);
    }
  },
  // Equipment
  addNewEquipment({ commit, getters }, payload) {
    commit('SET_EQUIPMENT_ITEMS', [...getters.equipmentItems, payload]);
  },
  deleteEquipment({ commit, getters }, id) {
    commit(
      'SET_EQUIPMENT_ITEMS',
      getters.equipmentItems.filter((el) => el.id !== id)
    );
  },
  toggleEquipmentsEditMode({ commit, getters }, id) {
    commit(
      'SET_EQUIPMENT_ITEMS',
      getters.equipmentItems.map((el) => {
        if (el.id === id) {
          el.isEditMode = !el.isEditMode;
        }

        return el;
      })
    );
  },
  // Pricing
  addNewPricingSection({ commit, getters }, payload) {
    commit('SET_PRICING_SECTIONS', [...getters.pricingSections, payload]);
  },
  deletePricingSection({ commit, getters }, id) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.filter((el) => el.id !== id)
    );
  },
  setPricingSectionsNoteVisibility({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.id) {
          el.attributes.show_important_notice = props.state;
        }

        return el;
      })
    );
  },
  setPricingSectionIsOpen({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.id) {
          el.attributes.is_collapsed = props.is_collapsed;
        }

        return el;
      })
    );
  },
  sortPricingSectionsByQueue({ commit, getters }) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el, i) => {
        return {
          ...el,
          attributes: {
            ...el.attributes,
            queue: i,
          },
        };
      })
    );
  },
  addNewPricingSectionItem({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.pricing_items.data = [...el.attributes.pricing_items.data, props.item];
        }

        return el;
      })
    );
  },
  deletePricingSectionItem({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.pricing_items.data = el.attributes.pricing_items.data.filter((elI) => elI.id !== props.id);
        }

        return el;
      })
    );
  },
  setPricingSectionItemsNoteVisibility({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.pricing_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.show_client_note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  deletePricingSectionItemsNote({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.pricing_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  setPricingSectionItemsInternalNoteVisibility({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.pricing_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.show_internal_note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  deletePricingSectionItemsInternalNote({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.pricing_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.internal_note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  setPricingSectionItemsPriceDividedByHundred({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.price /= 100;

          return el;
        }

        return el;
      })
    );
  },
  togglePricingSectionItemsPriceFormat({ commit, getters }, props) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.pricing_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.price_format = elI.attributes.price_format === 'Default' ? 'To be determined' : 'Default';
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  sortPricingSectionItemsByQueue({ commit, getters }) {
    commit(
      'SET_PRICING_SECTIONS',
      getters.pricingSections.map((el) => {
        return {
          ...el,
          attributes: {
            ...el.attributes,
            pricing_items: {
              data: el.attributes.pricing_items.data.map((elI, i) => {
                return {
                  ...elI,
                  attributes: {
                    ...elI.attributes,
                    queue: i,
                  },
                };
              }),
            },
          },
        };
      })
    );
  },
  // Planting
  addNewPlantingSection({ commit, getters }, payload) {
    commit('SET_PLANTING_SECTIONS', [...getters.plantingSections, payload]);
  },
  deletePlantingSection({ commit, getters }, id) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.filter((el) => el.id !== id)
    );
  },
  setPlantingSectionsNoteVisibility({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.id) {
          el.attributes.show_important_notice = props.state;
        }

        return el;
      })
    );
  },
  setPlantingSectionIsOpen({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.id) {
          el.attributes.is_collapsed = props.is_collapsed;
        }

        return el;
      })
    );
  },
  sortPlantingSectionsByQueue({ commit, getters }) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el, i) => {
        return {
          ...el,
          attributes: {
            ...el.attributes,
            queue: i,
          },
        };
      })
    );
  },
  addNewPlantingSectionItem({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.planting_items.data = [...el.attributes.planting_items.data, props.item];
        }

        return el;
      })
    );
  },
  deletePlantingSectionItem({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.planting_items.data = el.attributes.planting_items.data.filter((elI) => elI.id !== props.id);
        }

        return el;
      })
    );
  },
  setPlantingSectionItemsNoteVisibility({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.planting_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.show_client_note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  deletePlantingSectionItemsNote({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.planting_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  setPlantingSectionItemsInternalNoteVisibility({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.planting_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.show_internal_note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  deletePlantingSectionItemsInternalNote({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.planting_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.internal_note = props.state;
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  setPlantingSectionItemsPriceDividedByHundred({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.price /= 100;

          return el;
        }

        return el;
      })
    );
  },
  togglePlantingSectionItemsPriceFormat({ commit, getters }, props) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        if (el.id === props.sectionId) {
          el.attributes.planting_items.data.map((elI) => {
            if (elI.id === props.id) {
              elI.attributes.price_format = elI.attributes.price_format === 'Default' ? 'To be determined' : 'Default';
            }

            return el;
          });
        }

        return el;
      })
    );
  },
  sortPlantingSectionItemsByQueue({ commit, getters }) {
    commit(
      'SET_PLANTING_SECTIONS',
      getters.plantingSections.map((el) => {
        return {
          ...el,
          attributes: {
            ...el.attributes,
            planting_items: {
              data: el.attributes.planting_items.data.map((elI, i) => {
                return {
                  ...elI,
                  attributes: {
                    ...elI.attributes,
                    queue: i,
                  },
                };
              }),
            },
          },
        };
      })
    );
  },
  // Inclusions
  addNewInclusion({ commit, getters }, payload) {
    commit('SET_INCLUSIONS', [...getters.inclusions, payload]);
  },
  deleteInclusion({ commit, getters }, id) {
    commit(
      'SET_INCLUSIONS',
      getters.inclusions.filter((el) => el.id !== id)
    );
  },
  // Scheduled items
  addNewPaymentScheduleItem({ commit, getters }, payload) {
    commit('SET_PAYMENT_SCHEDULE_ITEMS', [...getters.paymentScheduleItems, payload]);
  },
  deletePaymentScheduleItem({ commit, getters }, id) {
    commit(
      'SET_PAYMENT_SCHEDULE_ITEMS',
      getters.paymentScheduleItems.filter((el) => el.id !== id)
    );
  },
};

const mutations = {
  SET_CURRENT_PROPOSAL(state, payload) {
    state.currentProposal = payload;
  },
  SET_TOP_MENU(state, payload) {
    state.topMenu = payload;
  },
  SET_IS_LOADED(state, payload) {
    state.isLoaded = payload;
  },
  SET_CURRENT_STEP(state, payload) {
    state.currentStep = payload;
  },
  SET_CURRENT_ROUTE(state, payload) {
    state.currentRoute = payload;
  },

  SET_MESSAGE(state, message) {
    state.message = message;
  },

  SET_IS_RENOVATE(state, payload) {
    state.isRenovate = payload;
  },
  SET_CREATED_AT(state, payload) {
    state.created_at = payload;
  },
  SET_UPDATED_AT(state, payload) {
    state.updated_at = payload;
  },
  SET_STATE(state, payload) {
    state.state = payload;
  },
  SET_SCOPE_TYPE(state, payload) {
    state.scope_type = payload;
  },
  SET_CONSTRUCTION(state, payload) {
    state.construction = payload;
  },

  SET_FIBERGLASS_POOL_TEMPLATE_ID(state, payload) {
    state.fiberglass_pool_template_id = payload;
  },
  SET_FIBERGLASS_DETAILS(state, payload) {
    state.fiberglass_details = payload;
  },
  SET_IS_FIBERGLASS_CUSTOM(state, payload) {
    state.is_fiberglass_custom = payload;
  },

  SET_SHAPE(state, payload) {
    state.shape = payload;
  },
  SET_SHAPE_NAME(state, payload) {
    state.shape_name = payload;
  },
  SET_CUSTOM_SHAPE_TEXT(state, payload) {
    state.custom_shape_text = payload;
  },
  SET_SIZE_CATEGORY(state, payload) {
    state.size_category = payload;
  },
  SET_WIDTH(state, payload) {
    state.width = payload;
  },
  SET_LENGTH(state, payload) {
    state.length = payload;
  },
  SET_SHALLOW(state, payload) {
    state.shallow = payload;
  },
  SET_DEEPEND(state, payload) {
    state.deepend = payload;
  },
  SET_INTRODUCTION(state, payload) {
    state.introduction = payload;
  },
  SET_CONSTRUCTION_DETAILS(state, payload) {
    state.construction_details = payload;
  },
  SET_SPECIAL_NOTES(state, payload) {
    state.special_notes = payload;
  },
  SET_PRICING_AND_PAYMENT_INFORMATION(state, payload) {
    state.pricing_and_payment_information = payload;
  },
  SET_TERMS_CONDITIONS_WARRANTIES(state, payload) {
    state.terms_conditions_warranties = payload;
  },
  SET_VERSION(state, payload) {
    state.version = payload;
  },
  SET_DATE(state, payload) {
    state.date = payload;
  },
  SET_VALUE(state, payload) {
    state.value = payload;
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_INCLUSIONS(state, payload) {
    state.inclusions.data = payload;
  },
  SET_PRICING_SECTIONS(state, payload) {
    state.pricing_sections.data = payload;
  },
  SET_PRICING_SECTION_ACTIVE_INDEX(state, payload) {
    state.pricing_section_active_index = payload;
  },
  SET_EQUIPMENT_ITEMS(state, payload) {
    state.equipment_items.data = payload;
  },
  SET_PAYMENT_SCHEDULE_ITEMS(state, payload) {
    state.payment_schedule_items.data = payload;
  },
  SET_SUB_TOTAL(state, payload) {
    state.sub_total = payload;
  },
  SET_SUB_TOTAL_PERCENTAGE(state, payload) {
    state.sub_total_percentage = payload;
  },
  SET_EMPTY_PRICE(state, payload) {
    state.empty_price = payload;
  },
  SET_SUBJECT(state, payload) {
    state.subject.data = payload;
  },
  SET_USERS(state, payload) {
    state.users.data = payload;
  },
  SET_USER_IDS(state, payload) {
    state.user_ids = payload;
  },
  SET_PLANTING_SECTIONS(state, payload) {
    state.planting_sections.data = payload;
  },
  SET_PLANTING_SECTION_ACTIVE_INDEX(state, payload) {
    state.planting_section_active_index = payload;
  },
  SET_PDF_READY(state, payload) {
    state.pdf_ready = payload;
  },
  SET_PDF_FILE(state, payload) {
    state.pdf_file = payload;
  },
  SET_SHARED(state, payload) {
    state.shared = payload;
  },
  SET_SIGNED_PDF_FILE(state, payload) {
    state.signed_pdf_file = payload;
  },
  SET_SENT_TO_SIGN(state, payload) {
    state.sent_to_sign = payload;
  },

  SET_PRICING_TEMPLATE_CATEGORIES(state, payload) {
    state.pricing_template_categories = payload;
  },
  SET_PLANTING_TEMPLATE_CATEGORIES(state, payload) {
    state.planting_template_categories = payload;
  },

  SET_SHOW_PRICING_ITEM(state, payload) {
    state.show_pricing_item = payload;
  },
  SET_SHOW_PRICING_DESCRIPTION(state, payload) {
    state.show_pricing_description = payload;
  },
  SET_SHOW_PRICING_QUANTITY(state, payload) {
    state.show_pricing_quantity = payload;
  },
  SET_SHOW_PRICING_PRICE(state, payload) {
    state.show_pricing_price = payload;
  },
  SET_SHOW_PRICING_UNIT(state, payload) {
    state.show_pricing_unit = payload;
  },
  SET_SHOW_PRICING_WASTE(state, payload) {
    state.show_pricing_waste = payload;
  },
  SET_SHOW_PRICING_TOTAL(state, payload) {
    state.show_pricing_total = payload;
  },

  SET_SHOW_PLANTING_CATEGORY(state, payload) {
    state.show_planting_category = payload;
  },
  SET_SHOW_PLANTING_DESCRIPTION(state, payload) {
    state.show_planting_description = payload;
  },
  SET_SHOW_PLANTING_ITEM(state, payload) {
    state.show_planting_item = payload;
  },
  SET_SHOW_PLANTING_MULTIPLIER(state, payload) {
    state.show_planting_multiplier = payload;
  },
  SET_SHOW_PLANTING_NOTES(state, payload) {
    state.show_planting_notes = payload;
  },
  SET_SHOW_PLANTING_PRICE(state, payload) {
    state.show_planting_price = payload;
  },
  SET_SHOW_PLANTING_QUANTITY(state, payload) {
    state.show_planting_quantity = payload;
  },
  SET_SHOW_PLANTING_SIZE(state, payload) {
    state.show_planting_size = payload;
  },
  SET_SHOW_PLANTING_TOTAL(state, payload) {
    state.show_planting_total = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
