/* Register you global components and mixins here */
import Vue from 'vue';
/* Import Mixins */
import GlobalMixin from '@/mixins/GlobalMixin';

/* Import Components */
import PxScrollTop from '@/components/elements/PxScrollTop.vue';
import PxLoader from '@/components/utils/PxLoader.vue';
import PxToolbar from '@/components/elements/PxToolbar.vue';
import PxPlaceholder from '@/components/elements/PxPlaceholder.vue';
import PxNewTable from '@/components/elements/PxNewTable.vue';
import PxNewModal from '@/components/elements/PxNewModal.vue';
import PxInput from '@/components/elements/PxInput.vue';
import PxPagination from '@/components/elements/PxPagination.vue';
import PxBtn from '@/components/elements/PxBtn.vue';
import PxSelect from '@/components/elements/PxSelect.vue';
import PxCounter from '@/components/elements/PxCounter.vue';
import PxDropdown from '@/components/elements/PxDropdown.vue';
import PxDropdownContent from '@/components/elements/PxDropdownContent.vue';
import PxEditor from '@/components/elements/PxEditor.vue';
import PxProgressWithText from '@/components/elements/PxProgressWithText.vue';
import PxListHasIconItem from '@/components/elements/PxListHasIconItem.vue';
import PxMoney from '@/components/elements/PxMoney.vue';

/* KeenThemes Components */
import PxSticky from '@/components/keenthemes/PxSticky.vue';
import PxDrawer from '@/components/keenthemes/PxDrawer.vue';
import PxSelect2 from '@/components/keenthemes/PxSelect2.vue';

/* Register Mixins */
Vue.mixin(GlobalMixin);

/* Register Components */
Vue.component('PxLoader', PxLoader);
Vue.component('PxScrollTop', PxScrollTop);
Vue.component('PxToolbar', PxToolbar);
Vue.component('PxPlaceholder', PxPlaceholder);
Vue.component('PxNewTable', PxNewTable);
Vue.component('PxNewModal', PxNewModal);
Vue.component('PxInput', PxInput);
Vue.component('PxPagination', PxPagination);
Vue.component('PxBtn', PxBtn);
Vue.component('PxSelect', PxSelect);
Vue.component('PxCounter', PxCounter);
Vue.component('PxDropdown', PxDropdown);
Vue.component('PxDropdownContent', PxDropdownContent);
Vue.component('PxEditor', PxEditor);
Vue.component('PxProgressWithText', PxProgressWithText);
Vue.component('PxListHasIconItem', PxListHasIconItem);
Vue.component('PxMoney', PxMoney);

/* KeenThemes Components */
Vue.component('PxSticky', PxSticky);
Vue.component('PxDrawer', PxDrawer);
Vue.component('PxSelect2', PxSelect2);
