// import Adjustments from '@/api/Adjustments';
// import Payments from '@/api/Payments';
import Proposal from '@/api/Proposal';
import Project from '@/api/Project';

const state = {
  details: null,
  contracts: [],
  proposals: [],
  changeOrders: [],
  hasSigned: false,
  contract: [],
  statements: [],
  internalNotes: null,
  activity: null,

  contacts: [],
  statistics: [],

  breadcrumbs: [],
  tabs: [
    {
      id: '1001',
      title: 'Contracts',
      to: 'projects-single.contracts',
    },
    {
      id: '1002',
      title: 'Statements',
      to: 'projects-single.statements',
    },
    {
      id: '1003',
      title: 'Internal Notes',
      to: 'projects-single.internal-notes',
    },
    {
      id: '1004',
      title: 'Activity',
      to: 'projects-single.activity',
    },
  ],
  message: null,

  // selectedRows: [],
};

const getters = {
  details(state) {
    return state.details;
  },
  contracts(state) {
    return state.contracts;
  },
  proposals(state) {
    return state.proposals;
  },
  changeOrders(state) {
    return state.changeOrders;
  },
  hasSigned(state) {
    return state.hasSigned;
  },
  contract(state) {
    return state.contract;
  },
  statements(state) {
    return state.statements;
  },
  internalNotes(state) {
    return state.internalNotes;
  },
  activity(state) {
    return state.activity;
  },

  contacts(state) {
    return state.contacts;
  },
  statistics(state) {
    return state.statistics;
  },

  breadcrumbs(state) {
    return state.breadcrumbs;
  },
  tabs(state) {
    return state.tabs;
  },
  message(state) {
    return state.message;
  },

  // selectedRows(state) {
  //   return state.selectedRows;
  // },
};

const actions = {
  async details({ commit }, { id, params = {} }) {
    try {
      if (params.fields) params.fields = params.fields || '';

      const project = await Project.single(id, { params });
      const details = await project.data.data;

      await commit('SET_DETAILS', details);

      await this.dispatch('projectsSingle/breadcrumbs');
      await this.dispatch('projectsSingle/contacts');
      await this.dispatch('projectsSingle/statistics');

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async hasSigned({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      if (params.fields) params.fields = params.fields || '';
      params.where = {};
      params.where.current = true;
      params.where.archive = false;
      params.where.status = 'Signed';
      if (params.subject_id) params.where.subject_id = params.subject_id;
      delete params.subject_id;

      const req = await Proposal.index({ params });
      const reqData = await req.data.data;

      await commit('SET_HAS_SIGNED', !!reqData.length);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },
  async contract({ commit, getters }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {
        current: true,
        archive: false,
        status: 'Signed',
      };
      if (params.fields) params.fields = params.fields || '';
      if (typeof params.id !== 'undefined') params.where.subject_id = params.id;
      delete params.id;
      if (typeof params.scope_type !== 'undefined') params.where.scope_type = params.scope_type;
      const scopeType = params.scope_type;
      delete params.scope_type;

      const req = await Proposal.index({ params });
      const reqData = req.data.data[0];

      await commit('SET_CONTRACT', [
        ...getters.contract,
        {
          type: scopeType,
          data: reqData,
        },
      ]);

      return true;
    } catch (e) {
      await commit('SET_CONTRACT', [...getters.contract]);
    }
  },
  async statements({ commit }, params = {}) {
    try {
      params.elasticsearch = true;
      params.per = params.per || 10;
      params.page = params.page || 1;
      params.order = params.order || 'desc';
      params.order_by = params.order_by || 'sid';
      params.where = {
        current: true,
        archive: false,
        status: 'Signed',
      };
      if (params.fields) params.fields = params.fields || '';
      if (typeof params.id !== 'undefined') params.where.subject_id = params.id;
      delete params.id;

      const req = await Proposal.index({ params });
      const reqData = await req.data.data;

      await commit('SET_STATEMENTS', reqData);

      return true;
    } catch (e) {
      throw new Error(e);
    }
  },

  contacts({ commit, getters }) {
    const contacts = [
      {
        id: '1001',
        title: `${getters.details.attributes.first_name || ''} ${getters.details.attributes.last_name || ''}`,
        icon: '/assets/media/icons/custom/user.svg',
      },
      {
        id: '1002',
        title: getters.details.attributes.phone || '',
        icon: '/assets/media/icons/custom/tel.svg',
      },
      {
        id: '1003',
        title: getters.details.attributes.email || '',
        icon: '/assets/media/icons/custom/mail.svg',
      },
    ];

    commit('SET_CONTACTS', contacts);

    return true;
  },
  statistics({ commit, getters }) {
    const statistics = [
      {
        id: 'single-project-project-breakdown-1001',
        title: 'Landscaping',
        sum: getters.details?.attributes?.statistics?.landscaping?.sum || '0',
        qty: getters.details?.attributes?.statistics?.landscaping?.qty || '0',
        bg: 'bg-success',
      },
      {
        id: 'single-project-project-breakdown-1002',
        title: 'Pool',
        sum: getters.details?.attributes?.statistics?.pool?.sum || '0',
        qty: getters.details?.attributes?.statistics?.pool?.qty || '0',
        bg: 'bg-info',
      },
      {
        id: 'single-project-project-breakdown-1003',
        title: 'Woodwork',
        sum: getters.details?.attributes?.statistics?.woodwork?.sum || '0',
        qty: getters.details?.attributes?.statistics?.woodwork?.qty || '0',
        bg: 'bg-danger',
      },
      {
        id: 'single-project-project-breakdown-1004',
        title: 'Change Orders',
        sum: getters.details?.attributes?.statistics?.change_orders?.sum || '0',
        qty: getters.details?.attributes?.statistics?.change_orders?.qty || '0',
        bg: 'bg-warning',
      },
    ];

    commit('SET_STATISTICS', statistics);

    return true;
  },

  breadcrumbs({ commit, getters }) {
    const breadcrumbs = [
      {
        id: '1001',
        title: 'Home',
        to: 'dashboard',
      },
      {
        id: '1002',
        title: 'Projects',
        to: 'projects',
      },
    ];
    if (getters.details.attributes.isArchived) {
      breadcrumbs.push({
        id: '1003',
        title: 'Archive',
        to: 'projects-archive',
      });
    }
    breadcrumbs.push({
      id: '1004',
      title: getters.details.attributes.address,
      to: { name: 'projects-single', params: { id: getters.details.id } },
    });
    breadcrumbs.push({
      id: '1005',
      title: 'Payment',
    });

    commit('SET_BREADCRUMBS', breadcrumbs);

    return true;
  },

  // toggleRow({ commit, getters }, payload) {
  //   if (payload.checked) {
  //     commit('SET_SELECTED_ROWS', [...getters.selectedRows, payload.value]);
  //   } else {
  //     commit(
  //       'SET_SELECTED_ROWS',
  //       [...getters.checkedRows].filter((el) => el !== payload.value)
  //     );
  //   }
  // },
};

const mutations = {
  SET_DETAILS(state, payload) {
    state.details = payload;
  },
  SET_HAS_SIGNED(state, payload) {
    state.hasSigned = payload;
  },
  SET_CONTRACT(state, payload) {
    state.contract = payload;
  },
  SET_STATEMENTS(state, payload) {
    state.statements = payload;
  },

  SET_CONTACTS(state, payload) {
    state.contacts = payload;
  },
  SET_STATISTICS(state, payload) {
    state.statistics = payload;
  },

  SET_BREADCRUMBS(state, payload) {
    state.breadcrumbs = payload;
  },
  SET_MESSAGE(state, payload) {
    state.message = payload;
  },

  // SET_SELECTED_ROWS(state, payload) {
  //   state.selectedRows = payload;
  // },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
